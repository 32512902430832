import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

/**
 * Component to handle an Image
 */
class Image extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options.layout)) {
      case 2:
        return this.gridLayout();
      case 3:
        return this.slideLayout();
      default:
      case 1:
        return this.singleImageLayout();
    }
  };

  /**
   * Render out layout 1 for this element
   */
  singleImageLayout = () => (
    <div id={this.id}>
      <img src={this.options.src} alt={this.options.alt} className="w-full h-auto" />
      <p className="italic text-center text-gray-600 mt-2">{this.options.caption}</p>
    </div>
  );

  /**
   * Render out layout 2 for this element
   */
  gridLayout = () => (
    <div id={this.id} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {this.options.images.map((image, index) => (
        <div key={index} className="relative">
          <img src={image.src} alt={image.alt} className="w-full h-64 object-cover" />
          <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">{image.caption}</p>
        </div>
      ))}
    </div>
  );

  /**
   * Render out layout 3 for this element
   */
  slideLayout = () => (
    <div id={this.id} className="max-w-4xl mx-auto">
      <Carousel showArrows={true} showThumbs={false} showStatus={false} showIndicators={false} infiniteLoop={true} autoPlay={true} interval={5000} className="max-h-[600px]">
        {this.options.images.map((image, index) => (
          <div key={index} className="h-full">
            <img src={image.src} alt={image.alt} className="object-contain h-full" />
            {image.caption && <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">{image.caption}</p>}
          </div>
        ))}
      </Carousel>
    </div>
  );

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Image;
