import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import HomeNavBar from "../elements/HomeNavBar";
import Button from "../elements/Button";
import { Helmet } from "react-helmet";

export class Home extends Component {
  renderInviteURL = () => {
    var bot_perms = 8;
    if (this.props.bot.invite_settings.invite_perms_int) {
      bot_perms = this.props.bot.invite_settings.invite_perms_int;
    }
    var url = `https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&guild_id=&scope=applications.commands%20bot&permissions=${bot_perms}`;
    return url;
  };

  render() {
    return (
      <>
        <HomeNavBar />

        <Helmet>
          <title>{this.props.bot.dash_settings.title ? this.props.bot.dash_settings.title : this.props.bot.name}</title>

        </Helmet>

        <div class="hero min-h-screen bg-base-200">
          <div class="hero-content pt-[-50px] text-center">
            <div class="max-w-md">
              <img
                src={this.props.bot.img ? this.props.bot.img : "https://cdn.discordapp.com/embed/avatars/0.png"}
                alt="bot icon"
                class="mx-auto w-48 rounded-full mb-6"
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if the default image also fails
                  e.target.src = "https://cdn.discordapp.com/embed/avatars/0.png";
                }}
              />
              <h1 class="text-5xl font-bold">
                {this.props.bot.dash_settings.title}
              </h1>
              <p class="py-6">{this.props.bot.dash_settings.description}</p>
              <div className="gap-x-3 flex flex-row items-center justify-center">
                <Link
                  to={"/servers"}
                  alt="invite"
                  style={{
                    background: this.props.bot.color_settings.primary,
                  }}
                  className="btn text-white"
                >
                  Invite {this.props.bot.name}
                </Link>

                <a
                  href={this.props.bot.dash_settings.support}
                  target="_blank"
                  className="btn btn-neutral text-white"
                >
                  Support Server
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  bot: state.data.bot,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
