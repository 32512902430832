import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display bot statistics
 */
class Statistics extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options || {}; // Provide a default empty object
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout) || 1) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      default:
      case 1:
        return this.layout1();
    }
  };

  /**
   * Render out layout 1 for this element (Grid Layout)
   */
  layout1 = () => {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8" id={this.id}>
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">{this.renderStats(1)}</div>
        </div>
      </div>
    );
  };

  /**
   * Render out layout 2 for this element (Inline Layout)
   */
  layout2 = () => {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8" id={this.id}>
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-wrap justify-around items-stretch">{this.renderStats(2)}</div>
        </div>
      </div>
    );
  };

  /**
   * Render out layout 3 for this element (Card Layout)
   */
  layout3 = () => {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8" id={this.id}>
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col space-y-6">{this.renderStats(3)}</div>
        </div>
      </div>
    );
  };

  renderStats = (layout) => {
    const stats = this.options?.stats || [];
    return stats.map((stat, index) => {
      let statContent = (
        <>
          {stat.icon && <FontAwesomeIcon icon={Icons[stat.icon]} className={`text-${layout === 2 ? "blue" : "red"}-500 text-${layout === 2 ? "3xl mb-3" : "4xl mb-4"}`} />}
          <p className={`text-${layout === 2 ? "4xl" : "5xl"} font-${layout === 3 ? "semibold" : "extrabold"} text-white mb-2`}>{stat.value}</p>
          <p className={`text-${layout === 2 ? "lg" : "xl"} text-gray-300`}>{stat.label}</p>
        </>
      );

      if (layout === 2) {
        return (
          <div key={index} className="flex-1 min-w-[200px] m-4 p-6 text-center" style={{ border: `1px solid ${this.options.layout2BorderColor || "#374151"}`, borderRadius: "0.5rem" }}>
            {statContent}
          </div>
        );
      } else if (layout === 3) {
        return (
          <div
            key={index}
            className="flex items-center p-4 rounded-r-lg"
            style={{
              borderLeft: `4px solid ${this.options.layout3HighlightColor || "#3B82F6"}`,
              backgroundColor: this.options.layout3BackgroundColor || "#1F2937",
            }}
          >
            {stat.icon && (
              <div className="flex-shrink-0 mr-4">
                <FontAwesomeIcon icon={Icons[stat.icon]} className="text-3xl" style={{ color: this.options.layout3HighlightColor || "#3B82F6" }} />
              </div>
            )}
            <div>{statContent}</div>
          </div>
        );
      } else {
        return (
          <div key={index} className="p-6 text-center">
            {statContent}
          </div>
        );
      }
    });
  };

  render() {
    return (
      <div className="text-white" id={this.id}>
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout) || 1}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Statistics;
