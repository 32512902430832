import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { buttonStyleMaker } from "../../utils/stylemaker";
import { connect } from "react-redux";

/**
 * This component is used to render buttons.
 * In the constructor, you will see that styles have been defined. These can be loaded in and set in here.
 * @param buttons Is an array is buttons that are to be rendered.
 * @param containerClass Is any additional tailwind class stuff that you want to add to the button container (the div that wraps the buttons).
 */
const ButtonRenderer = ({ buttons, containerClass, customClasses, botSettings }) => {
  const buttonColors = botSettings.home_settings?.buttonColors || {};
  const buttonStyles = botSettings.home_settings?.buttonStyles || {};

  const buttonStylesObject = buttonStyleMaker(buttonColors, buttonStyles);

  const renderButtons = () => {
    return buttons.map((button, index) => {
      const buttonStyle = buttonStylesObject[`${button.type || "primary"}-${button.style || "default"}`];
      return (
        <a href={button.link} key={index} style={{ ...buttonStyle, display: "inline-block", padding: "0.5rem 1rem" }} className={`${customClasses?.[button.type]} hover:opacity-80`}>
          {button.text}
          {button.icon && <FontAwesomeIcon icon={button.icon} style={{ marginLeft: "0.5rem" }} />}
        </a>
      );
    });
  };

  return (
    <div className={`flex items-center ${containerClass}`} style={{ gap: "1.25rem" }}>
      {renderButtons()}
    </div>
  );
};

const mapStateToProps = (state) => ({
  botSettings: state.data.bot,
});

export default connect(mapStateToProps)(ButtonRenderer);
