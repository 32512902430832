import React, { Component } from "react";
import Header from "./Header";
import Features from "./Features";
import FAQs from "./FAQs";
import Servers from "./Servers";
import Text from "./Text";
import Youtube from "./Youtube";
import Image from "./Image";
import Commands from "./Commands";
import Comments from "./Comments";
import CTA from "./CTA";
import Statistics from "./Statistics";
import Team from "./Team";
import Contact from "./Contact";
import Testimonials from "./Testimonials";
import Alert from "./Alert";
import ScrollToTop from "./ScrollToTop";
import Gap from "./Gap";
import clsx from "clsx";

/**
 * Used to render the components of a bot page
 */
class Renderer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.components = this.props.components;
  }

  renderComponent = (item, index) => {
    const ComponentMap = {
      header: Header,
      features: Features,
      faqs: FAQs,
      servers: Servers,
      text: Text,
      youtube: Youtube,
      image: Image,
      commands: Commands,
      comments: Comments,
      cta: CTA,
      statistics: Statistics,
      team: Team,
      contact: Contact,
      testimonials: Testimonials,
      alert: Alert,
      scrollToTop: ScrollToTop,
      gap: Gap,
    };

    const Component = ComponentMap[item.type];

    if (!Component) {
      console.warn(`Unknown component type: ${item.type}`);
      return null;
    }

    // Safely access options with default values
    const options = item.options || {};
    const { fullWidthBackground = false, backgroundType = "solid", backgroundColor = "#000000", backgroundColorColor, gradientStartColorColor, gradientEndColorColor, gradientAngle = 0, verticalPadding = "5rem" } = options;

    let backgroundStyle = {};
    if (fullWidthBackground) {
      if (backgroundType === "gradient" && gradientStartColorColor && gradientEndColorColor) {
        backgroundStyle = {
          background: `linear-gradient(${gradientAngle}deg, ${gradientStartColorColor}, ${gradientEndColorColor})`,
        };
      } else if (backgroundType === "solid") {
        const solidColor = backgroundColorColor || backgroundColor;
        backgroundStyle = { backgroundColor: solidColor };
      }
    }

    const paddingStyle = fullWidthBackground ? { paddingTop: verticalPadding, paddingBottom: verticalPadding } : {};

    return (
      <div key={item.id || index} className={fullWidthBackground ? "w-full" : ""} style={{ ...backgroundStyle, ...paddingStyle }}>
        <div className={clsx("max-w-7xl mx-auto px-4 sm:px-6 lg:px-8", !fullWidthBackground && "py-20")}>
          <Component options={options} id={item.id} />
        </div>
      </div>
    );
  };

  renderChildren = (children) => {
    return children.map((item, index) => {
      if (item.type === "cols") {
        return (
          <div key={item.id || index} className={clsx(`grid grid-cols-1 gap-5 items-center`, item.options?.num && `md:grid-cols-${item.options.num}`)}>
            {this.renderChildren(item.options.children)}
          </div>
        );
      }
      return this.renderComponent(item, index);
    });
  };

  render() {
    return <main className="flex flex-col">{this.renderChildren(this.components)}</main>;
  }
}

export default Renderer;
