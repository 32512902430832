import clsx from "clsx";
import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to handle some text
 */
class Text extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.styles = this.props.options.styles;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (this.options.layout) {
      default:
      case 1:
        return this.layout();
    }
  };

  layout = () => {
    switch (this.options.as) {
      default:
      case "h1":
        return <h1 className={clsx(this.styles)}>{this.options.text}</h1>;
      case "h2":
        return <h2 className={clsx(this.styles)}>{this.options.text}</h2>;
      case "h3":
        return <h3 className={clsx(this.styles)}>{this.options.text}</h3>;
      case "h4":
        return <h4 className={clsx(this.styles)}>{this.options.text}</h4>;
      case "h5":
        return <h5 className={clsx(this.styles)}>{this.options.text}</h5>;
      case "h6":
        return <h6 className={clsx(this.styles)}>{this.options.text}</h6>;
      case "p":
        return <p className={clsx(this.styles)}>{this.options.text}</p>;
      case "span":
        return <span className={clsx(this.styles)}>{this.options.text}</span>;
    }
  };

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Text;
