import React, { Component } from 'react';
import { connect } from 'react-redux';


// import { renderCustomEmojiCategories } from "../../../actions/index.js";




// import 'emoji-mart/css/emoji-mart.css';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import InputTitle from './InputTitle';
import { PlusCircleIcon } from '@heroicons/react/20/solid';

export class EmojiSelect extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showDropDown: false
        };

        this.node = React.createRef();
    }
    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }


    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.node && !this.node.current.contains(event.target)) {
            this.setState({ showDropDown: false });
        }
    };



    renderCustomEmojiCategories = (botData) => {
        var category = [];

        if (!botData.emojis) {
            return [];
        }

        botData.emojis.forEach(emoji => {
            var src = `https://cdn.discordapp.com/emojis/${emoji.id}.png`;
            if (emoji.animated) {
                src = `https://cdn.discordapp.com/emojis/${emoji.id}.gif`;
            }
            category.push(
                {
                    id: emoji.id,
                    name: emoji.name,
                    keywords: [emoji.name],
                    frequent: true,
                    native: emoji.name,
                    skins: [{ src: src }],
                }
            );
        });

        var categories = [];

        categories.push({
            id: "custom",
            name: "Server Emojis",
            emojis: category
        });



        // console.log(categories, 'CATEGORIES');

        return categories;
    };

    renderEmojiPicker = () => {

        var customCategories = this.renderCustomEmojiCategories(this.props.bot_data);

        var categoryIcons = [];
        if (customCategories.length > 0) {
            customCategories.forEach((category) => {
                var src = 'https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6ca814282eca7172c6_icon_clyde_white_RGB.svg';
                if (this.props.active_server?.icon) {
                    src = `https://cdn.discordapp.com/icons/${this.props.active_server.id}/${this.props.active_server.icon}.webp`;
                }
                categoryIcons[category.id] = {
                    src: src
                };
            });
        }



        return (
            <div ref={{
                // "absolute top-[13px] right-0 z-50" 
            }}
                className="absolute z-[2001] top-1 -left-8 right-0 md:left-0 md:right-0"
            // style={{ top: "15px", left: "0px", right: "0px", overflow: "hidden", width: "350px" }} 
            >
                <Picker noCountryFlags={false} emojiVersion={14} data={data} custom={customCategories}
                    categoryIcons={categoryIcons}
                    onEmojiSelect={this.onEmojiSelect} title="Pick your emoji..."></Picker>
            </div>

        );
    };

    onEmojiSelect = (emoji) => {
        // console.log(emoji, 'EMOJI');
        if (this.props.settings.maxLength != undefined && this.props.value.length >= this.props.settings.maxLength) {
            return;
        }
        const activeEmojis = [...this.props.value];
        activeEmojis.push(emoji);
        this.props.change(activeEmojis);
        this.setState({ showDropDown: false });
    };

    removeEmoji = (index) => {
        const activeEmojis = [...this.props.value];
        activeEmojis.splice(index, 1);
        this.props.change(activeEmojis);
    };



    renderEmojis = () => {
        return this.props.value.map((emoji, index) => (
            <li key={index} className="inline-flex items-center bg-gray-700 rounded-full px-2 py-1 mr-1 mb-1">
                <span className="mr-1">
                    {emoji.native ? (
                        emoji.native
                    ) : (
                        <img className="h-5 w-5" src={emoji.src} alt="Custom emoji" />
                    )}
                </span>
                <FontAwesomeIcon
                    onClick={() => this.removeEmoji(index)}
                    icon={faTimesCircle}
                    className="cursor-pointer hover:text-red-500 text-xs"
                />
            </li>
        ));
    };


    render() {
        return (
            <div className={`bg-menu-color rounded-lg p-4 w-full`}>
                <InputTitle settings={this.props.settings} refresh={true} />
                <div className="section-content-header mb-2">
                    {this.renderDescription()}
                </div>
                <div ref={this.node}>
                    <div className="input input-bordered py-2 px-3 relative rounded-md">
                        <ul className="flex flex-wrap items-center -my-1">
                            {this.renderEmojis()}
                            {(this.props.settings.maxLength == undefined || (this.props.value.length < this.props.settings.maxLength)) && (
                                <li className="inline-block">
                                    <PlusCircleIcon
                                        onClick={() => this.setState({ showDropDown: true })}
                                        className="h-6 w-6 text-gray-400 hover:text-gray-200 cursor-pointer ml-1"
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className='relative'>
                        {this.state.showDropDown && this.renderEmojiPicker()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot_data: state.data.server_data,
    premium: state.data.bot?.botghost_premium,
    active_server: state.data.active_server,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmojiSelect);
