import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faExclamationCircle, faInfoCircle, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to handle an Image
 */
class Alert extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;

    this.style = {
      card: {
        warning: "border-l-4 border-yellow-400 rounded-xl p-5 text-yellow-400 bg-yellow-400/20 hover:bg-yellow-400/30",
        error: "border-l-4 border-red-400 rounded-xl p-5 text-red-400 bg-red-400/20 hover:bg-red-400/30",
        info: "border-l-4 border-blue-400 rounded-xl p-5 text-blue-400 bg-blue-400/20 hover:bg-blue-400/30",
        success: "border-l-4 border-green-400 rounded-xl p-5 text-green-400 bg-green-400/20 hover:bg-green-400/30",
      },
      title: {
        warning: "text-yellow-500",
        error: "text-red-500",
        info: "text-blue-500",
        success: "text-green-500",
      },
      text: {
        warning: "text-yellow-400",
        error: "text-red-400",
        info: "text-blue-400",
        success: "text-green-400",
      },
    };

    this.icons = {
      warning: faExclamationTriangle,
      error: faExclamationCircle,
      info: faInfoCircle,
      success: faCheckCircle,
    };
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (this.options.layout) {
      default:
      case 1:
        return this.layout1();
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
    }
  };

  /**
   * Render out layout 1 for this element
   */
  layout1 = () => (
    <div className={clsx(this.style.card[this.options.type])} id={this.id}>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={this.icons[this.options.type]} className={clsx("h-5 w-5", this.style.title[this.options.type])} />
          <p className={clsx("text-xl font-medium", this.style.title[this.options.type])}>{this.options.title}</p>
        </div>
        <div className="">
          <p className={clsx(this.style.text[this.options.type])}>{this.options.text}</p>
        </div>
      </div>
    </div>
  );

  /**
   * Render out layout 2 for this element
   */
  layout2 = () => (
    <div className={clsx(this.style.card[this.options.type])} id={this.id}>
      <div className="flex gap-5 items-center">
        <FontAwesomeIcon icon={this.icons[this.options.type]} className={clsx("h-10 w-auto", this.style.title[this.options.type])} />

        <div className="">
          <p className={clsx("text-xl font-bold", this.style.title[this.options.type])}>{this.options.title}</p>
          <p className={clsx(this.style.text[this.options.type])}>{this.options.text}</p>
        </div>
      </div>
    </div>
  );

  /**
   * Render out layout 3 for this element
   */
  layout3 = () => (
    <div className={clsx(this.style.card[this.options.type], "border-l-0 border-t-4")} id={this.id}>
      <div className="flex gap-5 items-center flex-col justify-center">
        <FontAwesomeIcon icon={this.icons[this.options.type]} className={clsx("h-14 w-auto", this.style.title[this.options.type])} />
        <div className="">
          <p className={clsx("text-xl font-bold text-center", this.style.title[this.options.type])}>{this.options.title}</p>
          <p className={clsx("text-center", this.style.text[this.options.type])}>{this.options.text}</p>
        </div>
      </div>
    </div>
  );

  render() {
    return (
      <div className="text-white">
        {/* <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        /> */}
        {this.layoutRender()}
      </div>
    );
  }
}

export default Alert;
