import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { Component } from "react";

/**
 * Component to handle an Image
 */
class ScrollToTop extends Component {
    /**
     * Inits the component and the state
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {};
        this.options = this.props.options;
        this.position = {
            "bottom-left": "bottom-5 left-5",
            "bottom-right": "bottom-5 right-5",
            "top-left": "top-5 left-5",
            "top-right": "top-5 right-5",
            "bottom-middle": "bottom-5 left-1/2 transform -translate-x-1/2",
        };
        this.id = this.props.id;
    }

    /**
     * Renders the layouts
     * @returns the layout that is specified
     */
    layoutRender = () => {
        switch (this.options.layout) {
            default: case 1: return this.layout1();
        }
    }

    /**
     * Render out layout 1 for this element
     */
    layout1 = () => <div
        className={clsx(
            "fixed rounded-xl w-5 h-5 flex items-center justify-center bg-gray-800 p-5 hover:bg-gray-700 cursor-pointer duration-75",
            this.position[this.options.position])}
        onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        id={this.id}
    >
        <FontAwesomeIcon icon={faArrowUp} className="text-white" />
    </div>

    render() {
        return this.layoutRender();
    }
}

export default ScrollToTop;