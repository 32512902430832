import React, { Component } from "react";

/**
 * Component to hdisplay comments (and give the ability to enter in comment as well)
 */
class Comments extends Component {
    /**
     * Inits the component and the state
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {};
        this.options = this.props.options;
        this.id = this.props.id;
    }

    /**
     * Renders the layouts
     * @returns the layout that is specified
     */
    layoutRender = () => {
        switch (this.options.layout) {
            default: case 1: return this.layout1();
            case 2: return this.layout2();
            case 3: return this.layout3();
        }
    }

    /**
     * Render out layout 1 for this element
     */
    layout1 = () => {
        return <></>
    }

    /**
     * Render out layout 2 for this element
     */
    layout2 = () => {
        return <></>
    }

    /**
     * Render out layout 3 for this element
     */
    layout3 = () => {
        return <></>
    }

    render() {
        return this.layoutRender();
    }
}

export default Comments;