import React from "react";

/**
 * This component is used for the "header" for each display element.
 * @description Displays the pretitle, title, and description with customizable styles.
 * @param pretitle The text displayed above the main title.
 * @param title The main title of the element.
 * @param description The description of the element.
 * @param pretitleSize The size of the pretitle text.
 * @param titleSize The size of the title text.
 * @param descriptionSize The size of the description text.
 * @param pretitleColor The color of the pretitle text.
 * @param titleColor The color of the title text.
 * @param descriptionColor The color of the description text.
 * @param textJustification The alignment of the text (left, center, right).
 * @param layout The layout option (if needed for specific styling).
 */
const DisplayHeader = ({ pretitle, title, description, pretitleSize = "text-base", titleSize = "text-2xl", descriptionSize = "text-base", pretitleColor = "#FFFFFF", titleColor = "#FFFFFF", descriptionColor = "#D1D5DB", textJustification = "left", layout }) => {
  const alignmentClass = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  }[textJustification];

  const sizeOptions = {
    "text-base": "text-base",
    "text-lg": "text-lg",
    "text-xl": "text-xl",
    "text-2xl": "text-2xl",
    "text-3xl": "text-3xl",
    "text-4xl": "text-4xl",
    "text-5xl": "text-5xl",
    "text-6xl": "text-6xl",
    "text-7xl": "text-7xl",
    "text-8xl": "text-8xl",
    "text-9xl": "text-9xl",
  };

  return (
    <div className={`space-y-2  ${alignmentClass}`}>
      {pretitle && (
        <p className={`${pretitleSize}`} style={{ color: pretitleColor }}>
          {pretitle}
        </p>
      )}
      <h2 className={`font-bold ${titleSize}`} style={{ color: titleColor }}>
        {title}
      </h2>
      {description && (
        <p className={`${descriptionSize}`} style={{ color: descriptionColor }}>
          {description}
        </p>
      )}
    </div>
  );
};

export default DisplayHeader;
