import React, { Component } from "react";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect } from "react-redux";

import { updateModuleSettings, resetModuleSettings, recordModuleAction } from "../../actions";

import Dashboard from "../Dashboard";
// import CustomEventEditor from '../elements/CustomEventEditor';
import ModuleHeader from "../elements/ModuleHeader";
// import SectionHeader from '../elements/SectionHeader';
// import SlashCommand from '../elements/SlashCommand';
import LongText from "../module_inputs/inputs/LongText";
import ModuleSelect from "../module_inputs/inputs/ModuleSelect";
import ModuleToggle from "../module_inputs/inputs/ModuleToggle";
import ShortText from "../module_inputs/inputs/ShortText";
// import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ChannelSelect from "../module_inputs/inputs/ChannelSelect";
// import ReactGA from 'react-ga';
import InputSwitcher from "../module_inputs/inputs/InputSwitcher";
import RoleSelect from "../module_inputs/inputs/RoleSelect";
import MultiRoleSelect from "../module_inputs/inputs/MultiRoleSelect";
import MultiChannelSelect from "../module_inputs/inputs/MultiChannelSelect";
import MultiWordAdd from "../module_inputs/inputs/MultiWordAdd";
import CustomColorPicker from "../module_inputs/inputs/CustomColorPicker";
// import CustomModuleCommand from '../module_inputs/inputs/CustomModuleCommand';
import CustomSlots from "../module_inputs/inputs/CustomSlots";
// import PremiumSection from '../elements/PremiumSection';
// import server from '../../../api/server';
// import RippleLoader from '../elements/RippleLoader';
// import history from '../../../history';
import EmojiSelect from "../module_inputs/inputs/EmojiSelect";
import Button from "../elements/Button";
import ModuleShop from "../module_inputs/inputs/ModuleShop";
import history from "../../utils/history";
import Toggle from "../elements/Toggle";
import Command from "../elements/Command";
import { Helmet } from "react-helmet";

export class ModulePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      module: {},
      openModal: false,
      beta: false,
      loaded: false,
    };
  }
  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.setModule();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.modules.length != this.props.modules.length) {
      this.setModule();
    }

    if (this.state.module.id != this.props.match.params.module_id) {
      this.setModule();
      // Reload module page
    }
  }

  setModule = async () => {
    var module = this.props.modules.find(
      (m) => m.id === this.props.match.params.module_id
    );
    console.log(module, this.props.match.params);

    this.setState({
      module: module,
      loaded: true,
    });
    // if (!module.sections) {
    //     // Get the module data from the API and set it
    //     var moduleData = await server.get(`/modules/data/${this.props.match.params.id}`);
    //     if (moduleData && moduleData.data) {

    //         this.setState({
    //             module: moduleData.data,
    //             loaded: true
    //         });
    //     } else {
    //         history.push('/dashboard/modules');
    //     }
    // }
  };

  renderSections = () => {
    var sections = [];
    var moduleSections = this.state.module.sections;

    if (this.props.moduleSettings[this.state.module.id] !== undefined) {
      moduleSections = this.props.moduleSettings[this.state.module.id].sections;
      // Check if the premium of this.props.moduleSettings[this.state.module.id] is different from this.state.module.premium
      if (
        this.props.moduleSettings[this.state.module.id].premium !==
        this.state.module.premium &&
        this.state.module.premium == true
      ) {
        var module = { ...this.state.module };
        module.premium = this.props.moduleSettings[this.state.module.id].premium
          ? true
          : false;
        this.setState({ module: module });
      }
    }
    console.log(moduleSections, "MODULE SECTIONS");

    moduleSections.forEach((section) => {
      // Skip premium sections if user doesn't have premium
      if (section.premium && !this.props.premium) {
        return;
      }

      var passed = true;
      if (
        section.module_section_id &&
        this.props.moduleSettings[this.state.module.id]?.commands
      ) {
        var commands = this.props.moduleSettings[this.state.module.id].commands;
        commands.forEach((command) => {
          if (
            command.module_section_id === section.module_section_id &&
            command.enabled == false
          ) {
            passed = false;
          }
        });
      }
      if (!passed) return;

      var sectionInputs = [];

      sectionInputs.push(
        section.inputs.map((input) => {
          // Skip premium inputs if user doesn't have premium
          if (input.premium && !this.props.premium) {
            return null;
          }

          var value = input.defaultValue;
          if (
            this.props.moduleSettings[this.state.module.id] !== undefined &&
            this.props.moduleSettings[this.state.module.id].settings[input.id] != undefined
          ) {
            value = this.props.moduleSettings[this.state.module.id].settings[input.id].value;
          } else if (
            this.state.module.settings &&
            this.state.module.settings[input.id]?.value &&
            input.type != "channel_select" &&
            input.type != "role_select" &&
            input.type != "multi_role_select" &&
            input.type != "multi_channel_select"
          ) {
            value = this.state.module.settings[input.id]?.value;
          }

          // Replace the current show logic with the commented version
          if (input.show) {
            var show = input.show;
            var showConditions = [];

            // If show is an array, add them all to showConditions, else just add the show object
            if (Array.isArray(show)) {
              showConditions = show;
            } else {
              showConditions.push(show);
            }

            var passed = true;
            // Go through all the show conditions and check if they are met
            for (var i = 0; i < showConditions.length; i++) {
              var show = showConditions[i];
              // Check if the show id is in the module settings
              if (this.props.moduleSettings[this.state.module.id] !== undefined) {
                if (this.props.moduleSettings[this.state.module.id].settings[show.id] !== undefined) {
                  for (var key in this.props.moduleSettings[this.state.module.id].settings) {
                    var showValue = show.value;

                    if (typeof showValue == "object") {
                      if (show.id == key && !showValue.includes(this.props.moduleSettings[this.state.module.id].settings[key].value)) {
                        passed = false;
                      }
                    } else {
                      if (show.id == key && this.props.moduleSettings[this.state.module.id].settings[key].value != show.value) {
                        passed = false;
                      }
                    }
                  }
                  if (!passed) return null;
                }
              } else {
                // Go through all the inputs of each section. Find the show id and check if its default value is equal to the show value
                var sectionInputs = this.state.module.sections.find(section => section.inputs.find(input => input.id == show.id));

                if (sectionInputs) {
                  var showInput = sectionInputs.inputs.find(input => input.id == show.id);
                  if (showInput.defaultValue != show.value) {
                    passed = false;
                  }
                }
              }
            }

            if (!passed) return null;
          }

          // Render input based on type
          switch (input.type) {
            case "short":
              return (
                <ShortText
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );
            case "long":
              return (
                <LongText
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "short":
              return (
                <ShortText
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );
            case "long":
              return (
                <LongText
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );
            case "select":
              return (
                <ModuleSelect
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );
            case "toggle":
              return (
                <ModuleToggle
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                  value={value}
                  settings={input}
                />
              );
            case "channel_select":
              return (
                <ChannelSelect
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                  value={value}
                  settings={input}
                />
              );
            case "role_select":
              return (
                <RoleSelect
                  change={(value) => {
                    console.log(value, 'TEST');
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                  value={value}
                  settings={input}
                />
              );
            case "emoji_input":
              return (
                <EmojiSelect
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "input_switch":
              return (
                <InputSwitcher
                  value={value || { text: "" }}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );
            case "multi_role_select":
              return (
                <MultiRoleSelect
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "multi_channel_select":
              return (
                <MultiChannelSelect
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "word_add_input":
              return (
                <MultiWordAdd
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "color":
              return (
                <CustomColorPicker
                  value={value}
                  settings={input}
                  change={(value) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = value;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                />
              );

            case "shop":
              console.log("SHOP VALUE", value, input, "SHOP VALUE");
              // return <></>;
              return (
                <ModuleShop
                  onChange={(items) => {
                    var settings = {
                      ...this.props.moduleSettings[this.state.module.id],
                    };
                    settings.settings[input.id].value = items;
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings
                    );
                  }}
                  value={value}
                  settings={input}
                />
              );
            case "slot":
              return (
                <CustomSlots
                  value={value}
                  settings={input}
                  addSlot={(slot, index) => {
                    // console.log('value', slot, index);
                    var settings = { ...this.props.moduleSettings };
                    if (input.slot_type == "event") {
                      var slot_event = { ...input.slot_event };
                      slot_event.slot_id = slot.slot_id;
                      slot_event.input_id = input.id;
                      slot_event.name = `${input.individual_slot_name} #${settings[this.state.module.id].settings[input.id].value
                        .length + 1
                        }`;

                      if (index == "new") {
                        if (!settings[this.state.module.id].events) {
                          settings[this.state.module.id].events = [];
                        }
                        settings[this.state.module.id].events.push(slot_event);

                        if (slot_event.type == "timedEvent") {
                          // console.log("TIMED EVENT INSIDE");
                          // var timed_events = { ...this.props.bot.commands.timed_events };
                          // var existing_timed_event = timed_events.events.find((e) => e.id == slot_event.timer_id);
                          // if (!existing_timed_event && this.state.module.timed_events) {
                          //     var timed_event = this.state.module.timed_events.find((e) => e.id == slot_event.timer_id);
                          //     if (timed_event) {
                          //         timed_events.events.push(timed_event);
                          //         this.props.setBotModule(
                          //             {
                          //                 module: "timed_events",
                          //                 module_data: timed_events
                          //             }
                          //         );
                          //     }
                          // }
                        }
                        settings[this.state.module.id].settings[
                          input.id
                        ].value.push({
                          settings: slot,
                          // event: slot_event,
                        });
                      } else {
                        settings[this.state.module.id].settings[input.id].value[
                          index
                        ] = {
                          settings: slot,
                          // event: slot_event
                        };
                      }
                    } else if (input.slot_type == "value") {
                      if (index == "new") {
                        settings[this.state.module.id].settings[
                          input.id
                        ].value.push({
                          settings: slot,
                        });
                      } else {
                        settings[this.state.module.id].settings[input.id].value[
                          index
                        ] = {
                          settings: slot,
                        };
                      }
                    }

                    // "text-red"
                    // Go through settings and remove .event if it exists

                    settings[this.state.module.id].settings[
                      input.id
                    ].value.forEach((slot, index) => {
                      if (slot.event) {
                        delete slot.event;
                      }
                    });

                    // updateBotModuleSettings(this.state.module.id, settings[this.state.module.id]);

                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings[this.state.module.id]
                    );
                  }}
                  deleteSlot={(index) => {
                    // console.log('value', index);
                    var settings = { ...this.props.moduleSettings };
                    // Find corrosponding event
                    if (input.slot_type == "event") {
                      // var events = settings[this.state.module.id].events;
                      // var event_index = events.findIndex((event) => {
                      //     return event.slot_id == settings[this.state.module.id].settings[input.id].value[index].settings?.slot_id;
                      // });
                      // if (event_index !== -1) {
                      //     settings[this.state.module.id].events.splice(event_index, 1);
                      // }
                    }

                    settings[this.state.module.id].settings[
                      input.id
                    ].value.splice(index, 1);
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings[this.state.module.id]
                    );
                  }}
                />
              );
            // Add cases for other input types here...
            default:
              return null;
          }
        })
      );

      var inputs = sectionInputs[0].filter(input => input !== null);

      if (inputs.length > 0) {
        sections.push(
          <section
            key={section.title}
            className="gap-y-7 flex flex-col mt-5"
            premiumRequired={section.premium && !this.props.premium}
          >
            <div>
              <h3 className="text-white text-2xl font-bold">
                {section.title}
                {section.premium && !this.props.premium && (
                  <span className="text-yellow-500 text-sm ml-2">(Premium Only)</span>
                )}
              </h3>
            </div>
            {inputs}
          </section>
        );
      }
    });

    return sections;
  };

  renderCommands = () => {
    var commands = [];
    var module_commands = this.state.module.commands;

    if (this.props.moduleSettings[this.state.module.id]?.commands !== undefined) {
      module_commands = this.props.moduleSettings[this.state.module.id].commands;
    }

    if (!module_commands || module_commands.length === 0) {
      return null; // Return null if there are no commands
    }

    module_commands.forEach((command, i) => {
      commands.push(
        <Command
          key={i}
          command={command}
          command_index={i}
          module_id={this.state.module.id}
        />
      );
    });
    return commands;
  };


  render() {
    if (this.state.loaded === false)
      return (
        <Dashboard server_id={this.props.match.params.server_id}>

          <div className="h-full flex items-center justify-center w-full">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        </Dashboard>
      );
    return (
      <Dashboard server_id={this.props.match.params.server_id}>
        {/* Module Header */}
        <Helmet>
          <title>{this.state.module.name} - {this.props.bot.dash_settings.title ? this.props.bot.dash_settings.title : this.props.bot.name}</title>
        </Helmet>
        <header className="bg-menu-color flex lg:flex-row flex-col rounded-md flex py-5 lg:px-5 lg:py-8 items-center">
          <div className="flex">
            <img src={this.state.module.img} alt="" className="h-[50px]"></img>

            <div className="ml-3">
              <h3 className="text-3xl font-bold text-white">
                {this.state.module.name}
              </h3>
              <p className="text-sm">{this.state.module.description}</p>
            </div>
          </div>

          <div className="flex mt-5 lg:ml-auto">
            {this.props.moduleSettings[this.state.module.id] !== undefined ? (
              <Button
                className="btn btn-neutral mr-2"
                onClick={(e) => {
                  this.props.resetModuleSettings(this.state.module.id);
                }}
              >
                Reset to Default
              </Button>
            ) : null}
            {this.props.moduleSettings[this.state.module.id] == undefined ||
              this.props.moduleSettings[this.state.module.id].enabled == false ? (
              <Button
                color="primary"
                className="animate-pulse"
                onClick={(e) => {
                  // If it doesn't exist
                  if (
                    this.props.moduleSettings[this.state.module.id] ===
                    undefined
                  ) {
                    var moduleSettings = {};
                    this.state.module.sections.forEach((section) => {
                      section.inputs.forEach((input) => {
                        if (input.type.includes("multi")) {
                          var value =
                            input.defaultValue != undefined
                              ? input.defaultValue
                              : [];
                          // Check if the value exists in the settings
                          if (
                            this.state.module.settings &&
                            this.state.module.settings[input.id]?.value &&
                            input.type != "channel_select" &&
                            input.type != "role_select" &&
                            input.type != "multi_role_select" &&
                            input.type != "multi_channel_select"
                          ) {
                            value = this.state.module.settings[input.id]?.value;
                          }
                          moduleSettings[input.id] = {
                            value: value,
                            id: input.id,
                            name: input.name,
                            type: input.type,
                          };
                        } else if (input.type == "slot") {
                          var value =
                            input.defaultValue != undefined
                              ? input.defaultValue
                              : [];
                          // Check if the value exists in the settings
                          if (this.state.module.settings && this.state.module.settings[input.id]?.value) {
                            value = this.state.module.settings[input.id]?.value;
                          }
                          moduleSettings[input.id] = {
                            value: value,
                            id: input.id,
                            name: input.name,
                            type: input.type,
                            inputs: input.inputs,
                          };
                        } else {
                          var value =
                            input.defaultValue != undefined
                              ? input.defaultValue
                              : "";
                          // Check if the value exists in the settings
                          if (
                            this.state.module.settings &&
                            this.state.module.settings[input.id]?.value &&
                            input.type != "channel_select" &&
                            input.type != "role_select" &&
                            input.type != "multi_role_select" &&
                            input.type != "multi_channel_select"
                          ) {
                            value = this.state.module.settings[input.id]?.value;
                          }
                          moduleSettings[input.id] = {
                            value: value,
                            id: input.id,
                            name: input.name,
                            type: input.type,
                          };
                        }
                      });
                    });

                    var settings = { ...this.props.moduleSettings };

                    settings[this.state.module.id] = {
                      enabled: true,
                      settings: moduleSettings,
                      version: this.state.module.version,
                      commands: [...this.state.module.commands],
                      sections: [...this.state.module.sections],
                    };

                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings[this.state.module.id]
                    );

                  } else {
                    var settings = { ...this.props.moduleSettings };
                    settings[this.state.module.id].enabled = true;
                    console.log(settings);
                    this.props.updateModuleSettings(
                      this.state.module.id,
                      settings[this.state.module.id]
                    );
                  }

                  // Record module enable action
                  this.props.recordModuleAction(
                    this.state.module.id,
                    'enable',
                    this.props.bot.id,
                    this.props.match.params.server_id
                  );
                }}
              >
                Enable
              </Button>
            ) : (
              <Button
                className="btn-neutral"
                onClick={(e) => {
                  var settings = { ...this.props.moduleSettings };
                  settings[this.state.module.id].enabled = false;
                  console.log(settings);

                  this.props.updateModuleSettings(
                    this.state.module.id,
                    settings[this.state.module.id]
                  );

                  // Record module disable action
                  this.props.recordModuleAction(
                    this.state.module.id,
                    'disable',
                    this.props.bot.id,
                    this.props.match.params.server_id
                  );
                }}
              >
                Disable
              </Button>
            )}
          </div>
        </header>

        <div
          className={`${this.props.moduleSettings[this.state.module.id] == undefined ||
            this.props.moduleSettings[this.state.module.id]?.enabled == false
            ? "opacity-30 pointer-events-none"
            : ""
            }`}
        >
          <section>{this.renderSections()}</section>

          {this.renderCommands() && (
            <section className="gap-y-7 flex flex-col mt-5">
              <div>
                <h3 className="text-white text-2xl font-bold">Commands</h3>
              </div>

              <div>
                <div className="grid grid-cols-2 gap-y-6 gap-x-6">
                  {this.renderCommands()}
                </div>
              </div>
            </section>
          )}
        </div>


      </Dashboard>
    );
  }
}

const mapStateToProps = (state) => ({
  modules: state.data.modules,
  moduleSettings: state.data.serverSettings?.moduleSettings
    ? state.data.serverSettings.moduleSettings
    : {},
  premium: state.data.bot.botghost_premium,
  bot: state.data.bot, // Add this line to access bot data
});

const mapDispatchToProps = {
  updateModuleSettings,
  resetModuleSettings,
  recordModuleAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModulePage);
