import React from 'react';
import { connect } from 'react-redux';
import ButtonRenderer from '../elements/ButtonRenderer';
import { Helmet } from 'react-helmet';

const SimpleLandingPage = ({ bot }) => {
    const getBackgroundStyle = () => {
        const { home_settings } = bot;
        if (home_settings?.backgroundType === 'solid-color') {
            return { backgroundColor: home_settings.backgroundColor || '#1a202c' };
        } else if (home_settings?.backgroundType === 'linear-gradient') {
            const [color1, color2] = home_settings.gradientColors || ['#1a202c', '#2d3748'];
            const angle = home_settings.gradientAngle || 45;
            return { background: `linear-gradient(${angle}deg, ${color1}, ${color2})` };
        }
        return { backgroundColor: '#1a202c' }; // Default background
    };

    const getFontStyle = () => {
        const { home_settings } = bot;
        if (home_settings?.font) {
            return {
                fontFamily: `'${home_settings.font}', sans-serif`,
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale'
            };
        }
        return {}; // Return an empty object if no font settings
    };

    const backgroundStyle = getBackgroundStyle();
    const fontStyle = getFontStyle();

    const buttons = [
        {
            text: `Invite ${bot.name || 'Bot'}`,
            link: '/servers',
            type: 'primary',
            style: 'default'
        },
        {
            text: 'Support',
            link: bot.dash_settings?.support || '#',
            type: 'secondary',
            style: 'default'
        }
    ];

    return (
        <div className="flex flex-col items-center justify-center min-h-screen text-white" style={{ ...backgroundStyle, ...fontStyle }}>
            <Helmet>
                <title>{bot.name || 'Discord Bot'}</title>
                {bot.home_settings?.font && (
                    <link
                        href={`https://fonts.googleapis.com/css?family=${bot.home_settings.font.replace(' ', '+')}&display=swap`}
                        rel="stylesheet"
                    />
                )}
            </Helmet>
            <img
                src={bot.img || "https://cdn.discordapp.com/embed/avatars/0.png"}
                alt={`${bot.name || 'Bot'} icon`}
                className="w-32 h-32 rounded-full mb-8"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "https://cdn.discordapp.com/embed/avatars/0.png";
                }}
            />
            <h1 className="text-4xl font-bold mb-4">{bot.name || 'Welcome to Our Bot'}</h1>
            <p className="text-xl mb-8">{bot.description || 'A powerful Discord bot to enhance your server experience.'}</p>
            <ButtonRenderer
                buttons={buttons}
                containerClass="flex gap-4"
                customClasses={{
                    primary: {},
                    secondary: {}
                }}
                buttonColors={{}}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    bot: state.data.bot || {},
});

export default connect(mapStateToProps)(SimpleLandingPage);
