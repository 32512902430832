import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import Modules from "./components/pages/Modules";
import history from "./utils/history";
import Servers from "./components/pages/Servers";
import Home from "./components/pages/Home";
import { getData, recordPageView } from "./actions";
import { Helmet } from "react-helmet";
import ModulePage from "./components/pages/ModulePage";
import { Toaster } from "react-hot-toast";
import CommandList from "./components/pages/CommandList";
import Cookies from "universal-cookie";
import AuthCallback from "./components/AuthCallback";
import BuilderHome from "./components/pages/BuilderHome";
import SimpleLandingPage from "./components/pages/SimpleLandingPage"; // You'll need to create this component
import TOS from "./components/pages/TOS"; // Import the TOS component
import Privacy from "./components/pages/Privacy";

const Main = () => <h1>Hello world</h1>;
function hideError(e) {
  if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
    const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
    const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute("style", "display: none");
    }
  }
}

export class App extends Component {
  componentDidMount() {
    // recordPageView(window.location, this.props.bot.id);
    // If location == /redirect dont get data
    if (window.location.pathname !== "/redirect") {
      this.props.getData();
    } else {
      console.log("Hit");
      // get server_id from cookies
      var cookies = new Cookies();
      if (cookies.get("redirect_server_id")) {
        var server_id = cookies.get("redirect_server_id");
        // server_id will be 'id'. Need to convert it to a string so it doesnt get rounded
        // Need to remove the ' and '
        server_id = server_id.replace(/'/g, "");
        server_id = server_id.toString();
        console.log("SERVER ID", server_id);
        // cookies.remove("redirect_server_id");
        window.location.href = `/dashboard/${server_id}`;
        // history.push(`/dashboard/${server_id}`);
      } else {
        // Redirect to the server dashboard
        window.location.href = "/";
      }
    }
    window.addEventListener("error", (e) => {
      hideError(e);
    });
  }

  componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      recordPageView(document.title, this.props.bot.id, this.props.active_server);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bot.id !== this.props.bot.id) {
      // var page title

      recordPageView(document.title, this.props.bot.id, this.props.active_server);
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    if (this.props.limit_reached) {
      return (
        <div className="flex justify-center items-center h-screen">
          <div className="text-center">
            <h1 className="text-white text-3xl font-bold">Server Limit Reached :(</h1>
            <p>This Bot has reached its server limit on BotPanel. Contact the server administrator to increase their maximum server limit.</p>
          </div>
        </div>
      );
    } else if (!this.props.loaded) {
      return (
        <>
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
          </div>
        </>
      );
    } else {
      const { homeType } = this.props.bot.home_settings || {};

      return (
        <>
          {/* Position at bottom right */}
          {this.props.bot?.type == "free" ? (
            <>
              <div className="fixed right-0 bottom-0 z-10">
                <div
                  className="p-3 bg-darkGray rounded-t-md flex items-center"
                  onClick={() => {
                    window.open("https://botpanel.gg", "_blank");
                  }}
                >
                  <img src={process.env.PUBLIC_URL + "/img/logo.png"} className="h-5 mr-1"></img>
                  <span className="text-xs font-bold hover:underline hover:cursor-pointer text-white">Made with BotPanel</span>
                </div>
              </div>
            </>
          ) : null}
          <Toaster containerClassName="top-10 right-10 " position="top-right" />
          <Helmet>
            <title>{this.props.bot.dash_settings.title ? this.props.bot.dash_settings.title : this.props.bot.name}</title>
            <description>{this.props.bot.dash_settings?.description}</description>
            {/* Favicon */}
            <link rel="icon" type="image/png" sizes="16x16" href={this.props.bot.img}></link>
          </Helmet>
          <Router history={history}>
            <Switch>
              <Route path="/servers" component={Servers} />
              <Route path="/auth/callback" component={AuthCallback} />
              <Route path="/dashboard/:server_id/module/:module_id" component={ModulePage} />
              <Route path="/dashboard/:server_id/commands" component={CommandList} />
              <Route path="/dashboard/:server_id" component={Modules} />
              {this.props.bot.extra_pages?.tos && this.props.bot.sub_type !== "free" && <Route path="/terms" component={TOS} />}
              {this.props.bot.extra_pages?.privacy && this.props.bot.sub_type !== "free" && <Route path="/privacy" component={Privacy} />}
              <Route
                path="/redirect"
                component={() => {
                  // Redirect to the server dashboard
                }}
              />
              <Route exact path="/">
                {homeType === "dashboard" ? <Redirect to="/servers" /> : homeType === "advanced" && (this.props.bot?.type == "hobby" || this.props.bot?.type == "pro") ? <BuilderHome /> : <SimpleLandingPage />}
              </Route>
              {/* Catch-all route */}
              <Route component={SimpleLandingPage} />
            </Switch>
          </Router>
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  loaded: state.data.loaded,
  bot: state.data.bot ? state.data.bot : {},
  limit_reached: state.data.limit_reached,

  active_server: state.data?.active_server?.id ? state.data.active_server.id : null,
});

const mapDispatchToProps = {
  getData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
