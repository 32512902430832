import React, { Component } from 'react';
import { connect } from 'react-redux';

import InputTitle from './InputTitle';

export class ShortText extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            selectionStart: 0,
            selectionEnd: 0
        };
    }

    componentDidMount() {

    }

    handleInputChange = (e) => {
        var value = e.target.value;

        if (this.props.settings.inputType === "number") {
            if (value === "" || !isNaN(value)) {
                if (value !== "") {
                    const numValue = parseFloat(value);

                    if (numValue > this.props.settings.maxValue) {
                        value = this.props.settings.maxValue;
                    } else if (numValue < this.props.settings.minValue) {
                        value = this.props.settings.minValue;
                    }

                    if (this.props.settings.allowDecimals === false) {
                        value = Math.floor(numValue).toString();
                    }
                } else {
                    value = this.props.settings.defaultValue;
                }
            } else {
                return;
            }
        } else {
            const { selectionStart, selectionEnd } = e.target;
            this.setState({
                selectionStart,
                selectionEnd
            });
        }

        this.props.change(value);
    };

    componentDidUpdate(prevProps) {
        if (this.inputRef.current && prevProps.value !== this.props.value) {
            if (this.props.settings.inputType !== "number" && document.activeElement === this.inputRef.current) {
                this.inputRef.current.selectionStart = this.state.selectionStart;
                this.inputRef.current.selectionEnd = this.state.selectionEnd;
            }
        }
    }

    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };
    render() {
        return (
            <>
                <div className={`bg-menu-color rounded-lg p-6 w-full`}>
                    <div class="w-full">
                        <InputTitle settings={this.props.settings} />
                        <div className="section-content-header mb-2">
                            {this.renderDescription()}
                        </div>
                        <div className={`long-input ${this.props.showIcon ? 'long-input-error' : ''}`}>

                            <label className="form-control w-full ">
                                <div className="label">
                                    <span className="label-text">{this.props.settings.label}</span>
                                </div>
                                <input
                                    ref={this.inputRef}
                                    required
                                    type={this.props.settings.inputType || "text"}
                                    value={this.props.value}
                                    step={1}
                                    disabled={this.props.settings.premium && !this.props.premium}
                                    maxLength={this.props.settings.maxLength}
                                    className="input input-bordered w-full "
                                    min={this.props.settings?.minValue}
                                    max={this.props.settings?.maxValue}
                                    placeholder={this.props.settings.placeholder || ""}
                                    onChange={this.handleInputChange}
                                >
                                </input>
                            </label>




                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
    premium: state.data.bot?.botghost_premium
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShortText);;