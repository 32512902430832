import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display servers
 */
class Servers extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      case 4:
        return this.gridWithStatsLayout();
      default:
      case 1:
        return this.layout1();
    }
  };

  layout1 = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {this.options.servers?.map((item, index) => (
          <div className="flex h-24 flex-nowrap gap-5 bg-background-darkless rounded-lg hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image?.src} alt={`${item.name} Server Icon`} className="rounded-lg h-24 w-24" />
            <div className="my-auto overflow-hidden">
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.memberCount} members</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout2 = () => {
    return (
      <div className="grid grid-cols-5 gap-4 gap-y-16 mt-16">
        {this.options.servers?.map((item, index) => (
          <div className="bg-background-darkless rounded-lg relative p-2 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image?.src} alt={`${item.name} Server Icon`} className="rounded-full h-24 w-24 mx-auto -translate-y-2/4" />
            <div className="mt-1 -translate-y-2/4 text-center">
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.memberCount}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout3 = () => {
    return (
      <div className="mt-8">
        <div className="flex flex-wrap justify-center items-center max-w-4xl mx-auto" style={{ marginLeft: "-1rem" }}>
          {this.options.servers?.map((item, index) => (
            <div key={index} className="w-16 h-16 rounded-full overflow-hidden border-2 border-background-darkless" style={{ marginLeft: "-1rem", zIndex: index }}>
              <img src={item.image?.src} alt={item.name} className="w-full h-full object-cover" />
            </div>
          ))}
        </div>
      </div>
    );
  };

  gridWithStatsLayout = () => {
    const { statTitle, statDescription } = this.options;
    return (
      <div>
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-2">{statTitle}</h2>
          <p className="text-xl">{statDescription}</p>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {this.options.servers?.map((server, index) => (
            <div key={index} className="rounded-lg p-4 flex items-center space-x-3 bg-background-darkless">
              <img src={server.image?.src} alt={server.name} className="w-12 h-12 rounded-full" />
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium truncate">{server.name}</p>
                <p className="text-sm opacity-75 truncate">{server.memberCount} members</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="text-white" id={this.id}>
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Servers;
