import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";
import ButtonRenderer from "../elements/ButtonRenderer";

/**
 * Small little function to easily merge mutliple different classes
 * @param  {...any} classes
 * @returns
 */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/**
 * Component file for features
 */
class Features extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.gridLayout();
      default:
      case 1:
        return this.alternatingLayout();
    }
  };

  alternatingLayout = () => {
    return (
      <div className="mt-5 flex flex-col gap-8">
        {this.options.items?.map((item, index) => {
          const isEven = index % 2 === 0;
          const textAlign = isEven ? "text-left" : "text-right";
          const flexDirection = isEven ? "lg:flex-row" : "lg:flex-row-reverse";
          const buttonAlign = isEven ? "justify-start" : "justify-end";

          return (
            <div key={index} className={`flex flex-col ${flexDirection} items-center justify-between`}>
              <div className={`flex flex-col gap-3 flex-1 ${textAlign}`}>
                {item.name && <h3 className="text-3xl">{item.name}</h3>}
                {item.description && <p>{item.description}</p>}
                {item.buttons?.length > 0 && (
                  <div className={`flex ${buttonAlign} mt-4`}>
                    <ButtonRenderer buttons={item.buttons} />
                  </div>
                )}
              </div>
              {item.image?.src && <img src={item.image.src} alt={item.image?.caption || "Feature Image"} className="flex-1 w-full lg:w-96 rounded-lg mt-4 lg:mt-0" />}
            </div>
          );
        })}
      </div>
    );
  };

  gridLayout = () => {
    return (
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {this.options.items?.map((item, index) => (
          <div key={index} className="flex flex-col">
            {item.image?.src && (
              <div className="h-48 overflow-hidden rounded-lg">
                <img src={item.image.src} alt={item.image?.caption || "Feature Image"} className="w-full h-full object-cover" />
              </div>
            )}
            <div className="mt-4 flex flex-col flex-grow">
              {item.name && <h3 className="text-2xl font-semibold mb-2">{item.name}</h3>}
              {item.description && <p className="text-gray-300 mb-4 flex-grow">{item.description}</p>}
              {item.buttons?.length > 0 && (
                <div className="mt-auto">
                  <ButtonRenderer buttons={item.buttons} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
          fullWidthBackground={this.options.fullWidthBackground}
          backgroundType={this.options.backgroundType}
          backgroundColor={this.options.backgroundColor}
          gradientStartColor={this.options.gradientStartColor}
          gradientEndColor={this.options.gradientEndColor}
          gradientAngle={this.options.gradientAngle}
        />
        <div>{this.layoutRender()}</div>
      </div>
    );
  }
}

export default Features;
