import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";
import ButtonRenderer from "../elements/ButtonRenderer";

/**
 * Component to display commands that the bot has, with some filters maybe
 */
class Commands extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      case 4:
        return this.layout4();
      default:
      case 1:
        return this.layout1();
    }
  };

  layout1 = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 duration-75 ease-in">
      {this.options.commands?.map((item, index) => (
        <div className="flex h-24 flex-nowrap gap-5 bg-background-darkless rounded-lg items-center p-5 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
          <div className="w-16 h-16 bg-background-dark rounded-lg flex items-center justify-center">
            <span className="text-3xl font-extrabold">/</span>
          </div>
          <div className="my-auto overflow-hidden">
            <p className="text-xl w-full truncate font-bold">/{item.name}</p>
            <p className="text-gray-300">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  layout2 = () => (
    <div className="grid grid-cols-3 gap-4 mt-4">
      {this.options.commands?.map((item, index) => (
        <div className="flex h-24 flex-nowrap gap-5 p-5 cursor-default" key={index}>
          <div className="my-auto overflow-hidden">
            <p className="text-xl w-full truncate font-bold">/{item.name}</p>
            <p className="text-gray-300">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  layout3 = () => (
    <div className="flex justify-center items-center flex-col">
      {this.options.commands?.map((item, index) => (
        <div className="flex h-24 flex-nowrap gap-5 p-5 cursor-default" key={index}>
          <div className="my-auto overflow-hidden">
            <p className="text-xl w-full truncate font-bold text-center">/{item.name}</p>
            <p className="text-gray-300 text-center">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  layout4 = () => (
    <div className="flex justify-center flex-wrap">
      {this.options.commands?.map((item, index) => (
        <div className="flex h-24 flex-nowrap gap-5 p-5 cursor-default" key={index}>
          <div className="my-auto overflow-hidden">
            <p className="text-xl w-full truncate font-bold">/{item.name}</p>
            <p className="text-gray-300">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  render() {
    return (
      <div className="text-white space-y-5" id={this.id}>
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.options?.buttons && <ButtonRenderer buttons={this.options.buttons} containerClass="justify-center" />}
        {this.layoutRender()}
      </div>
    );
  }
}

export default Commands;
