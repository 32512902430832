/**
 * Style maker for the button renderer.
 * @param {*} colors 
 * @param {*} styles 
 * @returns button styles.
 */
const defaultButtonStyles = {
    default: { padding: '0.5rem 1rem', borderRadius: '0.25rem' },
    compact: { padding: '0.25rem 0.5rem', borderRadius: '0.125rem' },
    large: { padding: '0.75rem 1.5rem', borderRadius: '0.5rem' },
    pill: { padding: '0.5rem 1rem', borderRadius: '9999px' },
};

const buttonStyleMaker = (colors, styles) => {
    const defaultColors = {
        primary: { background: '#3B82F6', text: '#FFFFFF' },
        secondary: { background: '#6B7280', text: '#FFFFFF' },
        success: { background: '#10B981', text: '#FFFFFF' },
        danger: { background: '#EF4444', text: '#FFFFFF' },
    };

    const mergedColors = { ...defaultColors, ...colors };
    const mergedStyles = Object.keys(mergedColors).reduce((acc, colorType) => {
        acc[colorType] = { ...defaultButtonStyles.default, ...styles[colorType] };
        return acc;
    }, {});

    const buttonStyles = {};

    Object.keys(mergedColors).forEach(colorType => {
        const color = mergedColors[colorType];
        const style = mergedStyles[colorType];

        const gradientStyle = color.isGradient
            ? { backgroundImage: `linear-gradient(to right, ${color.gradientStart}, ${color.gradientEnd})` }
            : { backgroundColor: color.background };

        buttonStyles[`${colorType}-default`] = {
            ...style,
            ...gradientStyle,
            color: color.text,
            transition: 'all 0.3s',
            display: 'inline-block',
            textDecoration: 'none',
            border: 'none',
            cursor: 'pointer',
        };
    });

    return buttonStyles;
};

export { buttonStyleMaker };
