import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ButtonRenderer from "../elements/ButtonRenderer";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display a call to action
 */
class CTA extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      case 4:
        return this.layout4();
      default:
      case 1:
        return this.layout1();
    }
  };

  layout1 = () => (
    <div className="flex flex-col gap-8 justify-center items-center p-8 rounded-xl" id={this.id}>
      {this.options?.title && <h1 className="text-9xl font-bold text-center">{this.options.title}</h1>}
      {this.options?.subtitle && <p className="text-2xl font-medium text-center">{this.options.subtitle}</p>}
      {this.options?.buttons && <ButtonRenderer buttons={this.options.buttons} />}
    </div>
  );

  layout2 = () => (
    <div className="flex md:flex-row flex-col-reverse gap-20 justify-between items-center" id={this.id}>
      <div className="space-y-5">
        {this.options?.title && <h1 className="text-8xl font-bold">{this.options.title}</h1>}
        {this.options?.subtitle && <p className="text-2xl font-medium">{this.options.subtitle}</p>}
        {this.options?.buttons && <ButtonRenderer buttons={this.options.buttons} />}
      </div>
      {this.options?.image?.src && <img src={this.options.image.src} alt={this.options.image?.caption || "Header Image"} className="w-56 h-56" />}
    </div>
  );

  layout3 = () => (
    <div className="flex md:flex-row flex-col-reverse gap-20 justify-between items-center" id={this.id}>
      <div className="space-y-5">
        {this.options?.title && <h1 className="text-8xl font-bold">{this.options.title}</h1>}
        {this.options?.subtitle && <p className="text-2xl font-medium">{this.options.subtitle}</p>}
      </div>
      {this.options?.buttons && <ButtonRenderer buttons={this.options.buttons} />}
    </div>
  );

  layout4 = () => (
    <div className="flex md:flex-row flex-col-reverse gap-20 justify-between items-center" id={this.id}>
      {this.options?.image?.src && <img src={this.options.image.src} alt={this.options.image?.caption || "Header Image"} className="w-56 h-56 mx-auto" />}
      <div className="space-y-5">
        {this.options?.title && <h1 className="text-8xl font-bold">{this.options.title}</h1>}
        {this.options?.subtitle && <p className="text-2xl font-medium">{this.options.subtitle}</p>}
        {this.options?.features?.length > 0 && (
          <div className="grid grid-cols-2 grid-flow-row gap-4">
            {this.options.features.map((item, index) => (
              <div className="flex items-center gap-2" key={index}>
                <FontAwesomeIcon icon={faCircleCheck} className="text-red-500" />
                <p>{item}</p>
              </div>
            ))}
          </div>
        )}
        {this.options?.buttons && <ButtonRenderer buttons={this.options.buttons} />}
      </div>
    </div>
  );

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default CTA;
