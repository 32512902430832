import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from "prop-types";
import Style from 'style-it';

import { ChromePicker } from 'react-color';

export class Embed extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: "#1e1f22",
            showColorPicker: false,
            embed_id: "",
            cursorPositions: {
                author: { start: 0, end: 0 },
                title: { start: 0, end: 0 },
                description: { start: 0, end: 0 },
                thumbnail: { start: 0, end: 0 },
                image: { start: 0, end: 0 },
                footer: { start: 0, end: 0 },
                color: { start: 0, end: 0 },
                link: { start: 0, end: 0 }
            }
        };

        this.inputRefs = {
            author: React.createRef(),
            title: React.createRef(),
            description: React.createRef(),
            thumbnail: React.createRef(),
            image: React.createRef(),
            footer: React.createRef(),
            color: React.createRef(),
            link: React.createRef()
        };

        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.descriptionRef = React.createRef();
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    componentDidMount() {
        // Set embed id to random text
        this.setState({ embed_id: Math.random().toString(36).substring(7) });
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.descriptionRef.current && prevProps.value.description !== this.props.value.description) {
            this.descriptionRef.current.selectionStart = this.state.cursorPositions.description.start;
            this.descriptionRef.current.selectionEnd = this.state.cursorPositions.description.end;
        }

        // Check and restore cursor position for each input
        Object.keys(this.inputRefs).forEach(key => {
            const ref = this.inputRefs[key];
            const prevValue = prevProps.value?.[key];
            const currentValue = this.props.value?.[key];

            if (ref.current && prevValue !== currentValue) {
                const { start, end } = this.state.cursorPositions[key];
                ref.current.selectionStart = start;
                ref.current.selectionEnd = end;
            }
        });
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ showColorPicker: false });
        }
    }

    handleColorChange = (color) => {
        // console.log(color);
        this.embedChange(color.hex, "color");
    };

    renderColorPicker = () => {
        if (this.state.showColorPicker) {
            return (
                <div className="color-picker-container">
                    <ChromePicker color={this.props.value.color || "#1e1f22"} onChangeComplete={this.handleColorChange}></ChromePicker>
                </div>
            );
        } else {
            return;
        }
    };

    textAreaChange = (e) => {
        const { selectionStart, selectionEnd } = e.target;
        this.setState({ selectionStart, selectionEnd });

        var value = e.target.value;
        if (this.props.value.description == undefined || this.props.value.description.length < 2000) {
            const embed = { ...this.props.value };
            embed.description = value;
            this.props.update(embed);
        } else {
            const embed = { ...this.props.value };
            embed.description = value.substring(0, 2000);
            this.props.update(embed);
        }
    };

    embedChange = (value, type, e) => {
        if (e) {
            const { selectionStart, selectionEnd } = e.target;
            this.setState(prevState => ({
                cursorPositions: {
                    ...prevState.cursorPositions,
                    [type]: { start: selectionStart, end: selectionEnd }
                }
            }));
        }

        const embed = { ...this.props.value };
        embed[type] = value;
        this.props.update(embed);
    };

    render() {
        return (
            <>
                <Style>
                    {`
          ::before {
            background: ${this.props.value.color || "#1e1f22"};
          }
        `}
                    <div className="embed-container" >
                        <div className="embed" id={this.state.embed_id}>
                            <div className="embed-content">
                                <div className="embed-author">
                                    <input
                                        ref={this.inputRefs.author}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        value={this.props.value.author || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "author", e)}
                                        type="text"
                                        placeholder="Author"
                                        style={{ width: "68%" }}
                                    />
                                    <input
                                        ref={this.inputRefs.thumbnail}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        value={this.props.value.thumbnail || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "thumbnail", e)}
                                        type="text"
                                        placeholder="Thumbnail URL"
                                        style={{ width: "30%", float: "right" }}
                                    />
                                </div>

                                <div className="embed-title">
                                    <input
                                        ref={this.inputRefs.title}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        value={this.props.value.title || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "title", e)}
                                        type="text"
                                        placeholder="Title"
                                    />
                                </div>
                                <div className="embed-description">
                                    <textarea
                                        ref={this.descriptionRef}
                                        className='embed-textarea w-full'
                                        extra_classNames={this.props.commandSaveFailed == true ? "command-required" : ""}
                                        slash={this.props.slash}
                                        slash_options={this.props.options}
                                        required={this.props.required || false}
                                        variableEditor={this.props.variableEditor}
                                        disabled={this.props.disabled == true ? true : false}
                                        value={this.props.value.description}
                                        onChange={this.textAreaChange}
                                        placeholder="Content*"
                                    ></textarea>
                                </div>

                                <div className="embed-title">
                                    <input
                                        ref={this.inputRefs.image}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        value={this.props.value.image || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "image", e)}
                                        type="text"
                                        placeholder="Image URL"
                                    />
                                </div>

                                <div className="embed-footer">
                                    <input
                                        ref={this.inputRefs.footer}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        type="text"
                                        value={this.props.value.footer || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "footer", e)}
                                        placeholder="Footer"
                                        style={{ width: "100%" }}
                                    />
                                    <input
                                        ref={this.inputRefs.color}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        type="text"
                                        value={this.props.value.color || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "color", e)}
                                        placeholder="Color Hex"
                                        style={{ width: "49%" }}
                                    />
                                    <input
                                        ref={this.inputRefs.link}
                                        maxLength={"2024"}
                                        disabled={this.props.disabled}
                                        type="text"
                                        value={this.props.value.link || ""}
                                        onChange={(e) => this.embedChange(e.target.value, "link", e)}
                                        placeholder="Embed URL"
                                        style={{ width: "49%", float: "right" }}
                                    />

                                </div>
                            </div>

                            <div className="embed-color-picker" ref={this.setWrapperRef}>
                                <span>Color</span>
                                <div className="justify-content">
                                    <div onClick={(e) => { this.setState({ showColorPicker: !this.state.showColorPicker }); }} className="embed-color-picker-selected" style={{ backgroundColor: this.props.value.color || "#1e1f22" }}>
                                        <i class="bi bi-eyedropper" style={{ color: "#fff" }}></i>
                                    </div>
                                </div>
                                {this.renderColorPicker()}
                            </div>
                        </div>
                    </div>
                </Style>
            </>
        );
    }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};
Embed.propTypes = {
    children: PropTypes.element.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(Embed);
