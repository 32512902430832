import React, { Component } from 'react';
import { connect } from 'react-redux';
import Renderer from '../../components/display/Renderer';
import NavBar from '../../components/ui/NavBar';
import { Helmet } from 'react-helmet';

export class BuilderHome extends Component {
    getBackgroundStyle = () => {
        const { backgroundType, backgroundColor, gradientColors, gradientAngle } = this.props.home_settings;

        if (backgroundType === 'solid-color') {
            return { backgroundColor: backgroundColor || '#ffffff' };
        } else if (backgroundType === 'linear-gradient' && Array.isArray(gradientColors) && gradientColors.length >= 2) {
            const gradient = `linear-gradient(${gradientAngle || 45}deg, ${gradientColors[0]}, ${gradientColors[1]})`;
            return { background: gradient };
        } else {
            return { background: 'linear-gradient(45deg, #ffffff, #f0f0f0)' };
        }
    };

    getFontStyle = () => {
        const { font } = this.props.home_settings;
        return font ? { fontFamily: `'${font}', sans-serif` } : {};
    };

    render() {
        const backgroundStyle = this.getBackgroundStyle();
        const fontStyle = this.getFontStyle();
        const { bot, home_components } = this.props;

        return (
            <div className="scroll-smooth relative" style={{ ...backgroundStyle, ...fontStyle }}>
                <Helmet>
                    <title>{bot.name}</title>
                    {this.props.home_settings.font && (
                        <link
                            href={`https://fonts.googleapis.com/css?family=${this.props.home_settings.font.replace(' ', '+')}&display=swap`}
                            rel="stylesheet"
                        />
                    )}
                </Helmet>
                <NavBar bot={bot} />
                <Renderer components={home_components} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    home_components: state.data.bot.home_components || [],
    home_settings: state.data.bot.home_settings || {},
    bot: state.data.bot || {},
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderHome);
