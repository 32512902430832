import React, { Component } from "react";
import { Field, Input, Label, Textarea, Description } from "@headlessui/react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to handle an Contact
 */
class Contact extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      default:
      case 1:
        return this.layout1();
    }
  };

  formSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  };

  renderInputs = () =>
    this.options.fields.map((item, index) => (
      <Field className="space-y-1" key={index}>
        <Label className="block text-lg font-medium leading-6 text-white">{item.label}</Label>
        {item.type !== "textarea" && <Input name={item.name} type={item.type} placeholder={item.placeholder} required={item.required} className="bg-background-darkless block w-full rounded-md border-0 py-2 px-4 text-gray-100 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none outline-offset-2 focus:outline focus:outline-2 focus:outline-red-500/80" />}
        {item.type === "textarea" && <Textarea name={item.name} type={item.type} placeholder={item.placeholder} required={item.required} rows={4} className="bg-background-darkless block w-full rounded-md border-0 py-2 px-4 text-gray-100 placeholder:text-gray-400 sm:text-sm sm:leading-6 outline-none outline-offset-2 focus:outline focus:outline-2 focus:outline-red-500/80" />}
      </Field>
    ));

  /**
   * Render out layout 1 for this element
   */
  layout1 = () => (
    <form className="space-y-3" onSubmit={this.formSubmit}>
      {this.renderInputs()}
      <input type="submit" value={this.options?.button?.text || "Submit"} className="text-white bg-red-500/40 hover:bg-red-500 duration-75 py-2 px-4 rounded-xl w-max cursor-pointer" />
    </form>
  );

  /**
   * Render out layout 2 for this element
   */
  layout2 = () => (
    <form className="space-y-5" onSubmit={this.formSubmit}>
      <div className="grid grid-cols-2 grid-flow-row gap-4">{this.renderInputs()}</div>
      <input type="submit" value={this.options?.button?.text || "Submit"} className="text-white bg-red-500/40 hover:bg-red-500 duration-75 py-2 px-4 rounded-xl w-max cursor-pointer" />
    </form>
  );

  render() {
    return (
      <div className="space-y-5" id={this.id}>
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Contact;
