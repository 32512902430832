import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { setToken } from '../utils/auth';
import Cookies from "universal-cookie";
function AuthCallback() {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const redirect = params.get('redirect');
        // return;

        if (token) {
            setToken(token);
            // check for returnTo in the URL
            if (redirect) {
                history.push(redirect);
            } else {
                history.push('/servers');
            }

        } else {
            history.push('/login');
        }
    }, [location, history]);

    return <div>Authenticating...</div>;
}

export default AuthCallback;