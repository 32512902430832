import React, { Component } from "react";
import { connect } from "react-redux";
import Toggle from "./Toggle";
import { updateModuleSettings, setServerData } from "../../actions";
import Button from "./Button";
import RefreshBotData from "./RefreshBotData";
import server from "../../utils/server";
import { Helmet } from "react-helmet";


export const PERMISSIONS = [
  "CREATE_INSTANT_INVITE",
  "KICK_MEMBERS",
  "BAN_MEMBERS",
  "ADMINISTRATOR",
  "MANAGE_CHANNELS",
  "MANAGE_GUILD",
  "ADD_REACTIONS",
  "VIEW_AUDIT_LOG",
  "PRIORITY_SPEAKER",
  "STREAM",
  "VIEW_CHANNEL",
  "SEND_MESSAGES",
  "SEND_TTS_MESSAGES",
  "MANAGE_MESSAGES",
  "EMBED_LINKS",
  "ATTACH_FILES",
  "READ_MESSAGE_HISTORY",
  "MENTION_EVERYONE",
  "USE_EXTERNAL_EMOJIS",
  "VIEW_GUILD_INSIGHTS",
  "CONNECT",
  "SPEAK",
  "MUTE_MEMBERS",
  "DEAFEN_MEMBERS",
  "MOVE_MEMBERS",
  "USE_VAD",
  "CHANGE_NICKNAME",
  "MANAGE_NICKNAMES",
  "MANAGE_ROLES",
  "MANAGE_WEBHOOKS",
  "MANAGE_EMOJIS_AND_STICKERS",
  "USE_APPLICATION_COMMANDS",
  "REQUEST_TO_SPEAK",
  "MANAGE_EVENTS",
  "MANAGE_THREADS",
  "USE_PUBLIC_THREADS",
  "CREATE_PUBLIC_THREADS",
  "USE_PRIVATE_THREADS",
  "CREATE_PRIVATE_THREADS",
  "USE_EXTERNAL_STICKERS",
  "SEND_MESSAGES_IN_THREADS",
  "START_EMBEDDED_ACTIVITIES",
  "MODERATE_MEMBERS",
  "VIEW_CREATOR_MONETIZATION_ANALYTICS",
  "USE_SOUNDBOARD",
  "SEND_VOICE_MESSAGES"
].sort();

// Updated MultiSelect component
const MultiSelect = ({ type, options, selected, onSelect, onRemove }) => {
  const getPrefix = () => {
    switch (type) {
      case 'channel': return '#';
      case 'role': return '@';
      case 'permission': return '';
      default: return '';
    }
  };

  const getBadgeClass = () => {
    switch (type) {
      case 'channel': return 'bg-gray-700';
      case 'role': return 'bg-blue-700';
      case 'permission': return 'bg-green-700';
      default: return 'bg-gray-700';
    }
  };

  return (
    <div className="relative">
      <div className="select select-bordered w-full flex flex-wrap items-center p-1 min-h-[2.5rem] h-auto bg-gray-800 cursor-pointer">
        {selected.map((item, index) => (
          <div
            key={`${item.id}-${index}`}
            className={`badge ${getBadgeClass()} text-white gap-2 m-1 pl-1 pr-3 py-1 rounded flex items-center z-10`}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                onRemove(item);
              }}
              className="cursor-pointer focus:outline-none hover:text-red-500 text-gray-400 text-xl font-bold w-6 h-6 flex items-center justify-center"
            >
              ×
            </span>
            <span className="font-normal text-sm">{getPrefix()}{item.name}</span>
          </div>
        ))}
        <div className="flex-grow" onClick={() => document.getElementById('hidden-select').focus()}></div>
        <select
          id="hidden-select"
          className="absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer"
          onChange={(e) => {
            if (e.target.value) {
              const selectedOption = options.find(option => option.id === e.target.value);
              onSelect(selectedOption);
              e.target.value = ''; // Reset select after selection
            }
          }}
          value=""
        >
          <option value="" disabled hidden></option>
          {options.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export class Command extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      initName: "",
      description: "",
      loading: false,
      error: false,
      requiredChannels: [],
      blacklistedChannels: [],
      requiredRoles: [],
      blacklistedRoles: [],
      bypassPermissions: [],
      redirectOutput: "Do not redirect output",
      commandColor: null,
    };
  }

  componentDidMount() {

    if (this.props.command.bp_settings) {
      this.setState({
        requiredChannels: this.props.command.bp_settings.requiredChannels,
        blacklistedChannels: this.props.command.bp_settings.blacklistedChannels,
        requiredRoles: this.props.command.bp_settings.requiredRoles,
        blacklistedRoles: this.props.command.bp_settings.blacklistedRoles,
        bypassPermissions: this.props.command.bp_settings.bypassPermissions,
        commandColor: this.props.command.bp_settings.commandColor,
      });
    }
    this.setState({
      name: this.props.command.name,
      initName: this.props.command.name,
      description: this.props.command.description,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.command != this.props.command) {
    //   this.setState({
    //     name: this.props.command.name,
    //     initName: this.props.command.name,
    //     description: this.props.command.description,
    //   });
    // }
  }

  saveValidation = () => {
    if (this.state.name === this.state.initName) {
      return true;
    }

    var server_settings = this.props.serverSettings;
    var moduleIds = Object.keys(server_settings.moduleSettings);
    var commandNames = [];

    moduleIds.forEach((module_id) => {
      var module = server_settings.moduleSettings[module_id];
      console.log(module, 'MODULE HERE');
      if (module && module.commands) {
        module.commands.forEach((command, index) => {
          if (command.enabled !== false &&
            !(module_id === this.props.module_id && index === this.props.command_index)) {
            commandNames.push(command.name);
          }
        });
      }
    });

    return !commandNames.includes(this.state.name);
  };

  nameOnChange = (value) => {
    value = value.split(" ");

    var command = value[0];
    var subcommand = "";
    command = command.toLowerCase();
    command = command.substring(0, 32);
    command = command.replace(" ", "-");
    const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
    command = command.replace(regexForNonAlphaNum, "");
    if (value.length > 1) {
      // All others
      value.shift();
      subcommand = value.join(" ");
      subcommand = subcommand.toLowerCase();
      subcommand = subcommand.substring(0, 32);
      subcommand = subcommand.replace(" ", "-");
      const regexForNonAlphaNum = new RegExp(/[^\p{L}\p{N}_-]+/gu);
      subcommand = subcommand.replace(regexForNonAlphaNum, "");
      value = command + " " + subcommand;
    } else {
      value = command;
    }

    this.setState({
      name: value,
    });
  };

  handleMultiSelect = (type, value) => {
    this.setState(prevState => {
      // Check if the item already exists in the array
      const exists = prevState[type].some(item => item.id === value.id);
      if (!exists) {
        return { [type]: [...prevState[type], value] };
      }
      return prevState; // Return unchanged state if item already exists
    });
  };

  handleRemoveItem = (type, valueToRemove) => {
    this.setState(prevState => ({
      [type]: prevState[type].filter(item => item.id !== valueToRemove.id)
    }));
  };

  handleSave = () => {
    const { module_id, command_index, updateModuleSettings, serverSettings } = this.props;
    const module = serverSettings.moduleSettings[module_id];
    const command = module.commands[command_index];

    // Update the command with the new settings
    var bp_settings = {};
    bp_settings.requiredChannels = this.state.requiredChannels;
    bp_settings.blacklistedChannels = this.state.blacklistedChannels;
    bp_settings.requiredRoles = this.state.requiredRoles;
    bp_settings.blacklistedRoles = this.state.blacklistedRoles;
    bp_settings.bypassPermissions = this.state.bypassPermissions;
    bp_settings.commandColor = this.state.commandColor;


    command.bp_settings = bp_settings;

    // Update the module settings
    updateModuleSettings(module_id, module);

    // Close the modal
    const dialog = document.getElementById(`command_${module_id}.${command_index}`);
    dialog.close();
  };

  loadServerData = async () => {
    this.setState({ loading: true });
    try {
      const response = await server.get(`/client/server/${this.props.active_server.id}/data`);
      if (response?.data) {
        this.props.setServerData(response.data);
      }
    } catch (error) {
      console.error("Error loading server data:", error);
    } finally {
      this.setState({ loading: false });
    }
  };

  openModal = () => {
    const dialog = document.getElementById(
      `command_${this.props.module_id}.${this.props.command_index}`
    );
    dialog.showModal();

    // Load server data if not already loaded
    if (!this.props.server_data.loaded) {
      this.loadServerData();
    }
  };

  render() {
    const channelOptions = this.props.channels
      .filter(channel => channel.type === 0 || channel.type === 5) // Filter for text and announcement channels
      .map(channel => ({
        id: channel.id,
        name: channel.name
      }));

    const roleOptions = this.props.roles.map(role => ({
      id: role.id,
      name: role.name
    }));

    const permissionOptions = PERMISSIONS.map(permission => ({
      id: permission,
      name: permission.split('_').map(word => word.charAt(0) + word.slice(1).toLowerCase()).join(' ')
    }));

    const primaryColor = this.props.color_settings.primary;

    return (
      <>

        <dialog
          id={`command_${this.props.module_id}.${this.props.command_index}`}
          className="modal"
        >
          <div className="modal-box">
            <h3 className="font-bold text-xl">Overwrites for {this.props.command.name}</h3>

            <div className="mt-4">
              <p className="text-sm">{this.props.command.description}</p>
            </div>


            {/* Channels Section */}
            <div className="mt-4">
              <label className="form-control w-full">
                <div className="label flex flex-row items-center">
                  <span className="label-text">Enabled Channels</span>
                  <RefreshBotData className="ml-2" />
                </div>
                <MultiSelect
                  type="channel"
                  options={channelOptions}
                  selected={this.state.requiredChannels}
                  onSelect={(value) => this.handleMultiSelect('requiredChannels', value)}
                  onRemove={(value) => this.handleRemoveItem('requiredChannels', value)}
                />
              </label>
            </div>

            <div className="mt-4">
              <label className="form-control w-full">
                <div className="label flex items-center">
                  <span className="label-text flex-grow">Disabled Channels</span>
                  <RefreshBotData className="ml-2" />
                </div>
                <MultiSelect
                  type="channel"
                  options={channelOptions}
                  selected={this.state.blacklistedChannels}
                  onSelect={(value) => this.handleMultiSelect('blacklistedChannels', value)}
                  onRemove={(value) => this.handleRemoveItem('blacklistedChannels', value)}
                />
              </label>
            </div>

            {/* Divider */}
            <div className="divider my-4"></div>

            {/* Roles Section */}
            <div className="mt-4">
              <label className="form-control w-full">
                <div className="label flex items-center">
                  <span className="label-text flex-grow">Enabled Roles</span>
                  <RefreshBotData className="ml-2" />
                </div>
                <MultiSelect
                  type="role"
                  options={roleOptions}
                  selected={this.state.requiredRoles}
                  onSelect={(value) => this.handleMultiSelect('requiredRoles', value)}
                  onRemove={(value) => this.handleRemoveItem('requiredRoles', value)}
                />
              </label>
            </div>

            <div className="mt-4">
              <label className="form-control w-full">
                <div className="label flex items-center">
                  <span className="label-text flex-grow">Disabled Roles</span>
                  <RefreshBotData className="ml-2" />
                </div>
                <MultiSelect
                  type="role"
                  options={roleOptions}
                  selected={this.state.blacklistedRoles}
                  onSelect={(value) => this.handleMultiSelect('blacklistedRoles', value)}
                  onRemove={(value) => this.handleRemoveItem('blacklistedRoles', value)}
                />
              </label>
            </div>

            {/* Divider */}
            <div className="divider my-4"></div>

            {/* Permissions Section */}
            <div className="mt-4">
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">Required Permissions</span>
                </div>
                <MultiSelect
                  type="permission"
                  options={permissionOptions}
                  selected={this.state.bypassPermissions || []}
                  onSelect={(value) => this.handleMultiSelect('bypassPermissions', value)}
                  onRemove={(value) => this.handleRemoveItem('bypassPermissions', value)}
                />
              </label>
            </div>

            {/* Save and Close buttons */}
            <div className="modal-action mt-6 flex justify-between">
              <form method="dialog">
                <button className="btn">Close</button>
              </form>
              <Button
                color="primary"
                onClick={this.handleSave}
              >
                Save
              </Button>
            </div>
          </div>

          <form method="dialog" className="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>

        <div
          onClick={this.openModal}
          className={`bg-menu-color ${this.props.command.enabled == false ? "opacity-70" : ""
            } flex items-center rounded-lg p-6 w-full transition duration-300 ease-in-out hover:shadow-lg cursor-pointer`}
          style={{
            '--hover-bg-color': `${this.state.commandColor || this.props.color_settings.primary}33`,
            borderLeft: `4px solid ${this.state.commandColor || this.props.color_settings.primary}`
          }}
        >
          <div>
            <h3 className="text-white font-bold">/{this.props.command.name}</h3>
            <span className="text-sm">{this.props.command.description}</span>
          </div>

          <div
            className="ml-auto"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Toggle
              value={this.props.command.enabled != false ? true : false}
              onChange={(value) => {
                console.log(this);
                var server_settings = this.props.serverSettings;

                var module =
                  server_settings.moduleSettings[this.props.module_id];
                // console.log(module, 'MODULE HERE');

                var command = module.commands[this.props.command_index];
                command.enabled = value;
                this.props.updateModuleSettings(this.props.module_id, module);
              }}
              size="lg"
              color="primary"
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  serverSettings: state.data.serverSettings,
  moduleInfo: state.data.modules,
  roles: state.data.server_data?.roles || [],
  server_data: state.data.server_data,
  channels: state.data.server_data?.channels || [],
  active_server: state.data.active_server,
  color_settings: state.data.bot.color_settings,
  bot: state.data.bot
});

const mapDispatchToProps = {
  updateModuleSettings,
  setServerData
};

export default connect(mapStateToProps, mapDispatchToProps)(Command);
