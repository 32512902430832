import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display a youtube iframe
 */
class Youtube extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = { videoId: "" };
    this.options = this.props.options;
    this.id = this.props.id;
  }

  componentDidMount() {
    if (this.options?.url) {
      try {
        const urlParams = new URLSearchParams(new URL(this.options.url).search);
        const videoId = urlParams.get("v");
        this.setState({ videoId });
      } catch (error) {
        console.error("Invalid YouTube URL:", error);
      }
    }
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.props.options.layout)) {
      case 1:
        return this.layout1();
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      default:
        return this.layout1(); // fallback to layout1 if unknown layout
    }
  };

  /**
   * Render out layout 1 for this element
   */
  layout1 = () => <iframe id={this.id} className="mx-auto rounded-xl shadow-xl hover:-translate-y-1 duration-75 my-5 ease-linear" width="560" height="315" src={`https://www.youtube.com/embed/${this.state.videoId}?si=Y_Tf3_V5tC-z8-b5`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen />;

  /**
   * Render out layout 2 for this element
   */
  layout2 = () => <></>;

  /**
   * Render out layout 3 for this element
   */
  layout3 = () => <></>;

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Youtube;
