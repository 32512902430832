import React, { Component } from 'react';
import { connect } from 'react-redux';


import InputTitle from './InputTitle';

export class RoleSelect extends Component {
    renderDescription = () => {
        if (this.props.settings.html) {
            return <div dangerouslySetInnerHTML={{ __html: this.props.settings.description }}></div>;
        } else {
            return <p>{this.props.settings.description}</p>;
        }
    };

    renderSelectedRole = () => {
        if (this.props.value && this.props.value.name) {
            return <option value={JSON.stringify(this.props.value)}>{this.props.value.name}</option>;
        }
        return null;
    };

    renderChannels = () => {
        if (this.props.server_data.roles && this.props.server_data.roles.length > 0) {
            return this.props.server_data.roles.map((role) => (
                <option key={role.id} value={JSON.stringify({
                    id: role.id,
                    name: role.name
                })}>
                    {role.name}
                </option>
            ));
        }
        return null;
    };

    render() {
        return (
            <>
                <div className={`bg-menu-color rounded-lg p-6 w-full`}>
                    <div className="">
                        <InputTitle settings={this.props.settings} refresh={true} />
                        <div className="section-content-header mb-2">
                            {this.renderDescription()}
                        </div>
                        <div>
                            <select
                                value={JSON.stringify(this.props.value)}
                                onChange={(e) => {
                                    const value = JSON.parse(e.target.value);
                                    console.log(value, 'TEST');
                                    this.props.change(value);
                                }}
                                className='select select-bordered w-full'
                            >
                                <option disabled value={JSON.stringify({ id: "" })}>Pick a Role</option>
                                {this.renderSelectedRole()}
                                {this.renderChannels()}
                            </select>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    premium: state.data.bot?.botghost_premium,
    server_data: state.data.server_data
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelect);
