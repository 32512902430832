import { Bars3Icon } from '@heroicons/react/20/solid';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

export class HomeNavBar extends Component {
    render() {
        return (
            <nav className='navbar z-40 bg-menu-color fixed px-5'>
                <div className='flex-1'>
                    <div className='flex items-center gap-x-3'>
                        <img src={this.props.bot.img} className='rounded-full w-8'></img>
                        <Link to='/' className='text-white font-xl font-bold'>{this.props.bot.name}</Link>
                    </div>
                </div>

                {/* Mobile Menu */}
                <div className="flex-none md:hidden gap-2">

                    <div class="dropdown dropdown-end">
                        <div tabindex="0" role="button" class="btn m-1"><Bars3Icon className='h-6 w-6' /></div>
                        <ul class="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
                            <li><a href={this.props.bot.dash_settings?.support} target="_blank">Support</a></li>
                            <li><Link to="/servers">Servers</Link></li>
                            <li><Link to="/servers">Dashboard</Link></li>
                        </ul>
                    </div>
                </div>

                <div class="flex-none hidden md:block">
                    <ul class="menu menu-horizontal px-1">
                        <li><a href={this.props.bot.dash_settings?.support} target="_blank">Support</a></li>
                        <li><Link to="/servers">Servers</Link></li>
                        <li><Link to="/servers">Dashboard</Link></li>
                    </ul>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,

});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomeNavBar);