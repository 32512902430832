import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Popover, Transition, PopoverPanel, PopoverButton } from "@headlessui/react";
import { Fragment } from "react";
import ButtonRenderer from "../elements/ButtonRenderer";
import { useHistory } from "react-router-dom";

/**
 * UI component for the nav bar
 */
function NavBar({ bot, home_settings }) {
  const history = useHistory();
  const navBarSettings = home_settings?.navBar || {};
  const navItems = navBarSettings.items || [];
  const itemsGap = navBarSettings.itemsGap || 16; // Default to 16px if not set

  const handleNavigation = (e, href) => {
    e.preventDefault();
    if (href.startsWith("/")) {
      history.push(href);
    } else if (href.startsWith("#")) {
      document.querySelector(href).scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.open(href, "_blank", "noopener,noreferrer");
    }
  };

  const renderNavItems = (isMobile = false) => {
    let items = navItems.length > 0 ? navItems : [{ id: "default-support", label: "Support", type: "external", url: bot.dash_settings?.support || "#" }];

    return items.map((item) => {
      let href = "#";
      let target = "_self";
      let rel = "";

      if (item.type === "section") {
        href = `#${item.sectionId}`;
      } else if (item.type === "external") {
        href = item.url;
        if (item.openInNewTab) {
          target = "_blank";
          rel = "noopener noreferrer";
        }
      } else if (item.type === "page") {
        if (item.pageId === "servers") {
          href = "/servers";
        } else {
          href = `/page/${item.pageId}`;
        }
      }

      return (
        <a key={item.id} href={href} target={target} rel={rel} onClick={(e) => handleNavigation(e, href)} className={`text-gray-300 hover:text-white transition-colors duration-200 ${isMobile ? "text-lg py-2" : "text-sm font-medium"}`} style={isMobile ? {} : { marginRight: `${itemsGap}px` }}>
          {item.label}
        </a>
      );
    });
  };

  const renderCTAButton = (isMobile = false) => {
    const ctaButton = {
      text: navBarSettings.ctaText || `Invite ${bot.name}`,
      link: "/servers",
      type: navBarSettings.ctaStyle || "primary",
      style: "default",
      isNavCTA: true,
      onClick: (e) => {
        e.preventDefault();
        history.push("/servers");
      },
    };

    return (
      <ButtonRenderer
        buttons={[ctaButton]}
        containerClass={isMobile ? "w-full" : ""}
        customClasses={{
          primary: isMobile ? "w-full text-center" : "",
          secondary: isMobile ? "w-full text-center" : "",
          success: isMobile ? "w-full text-center" : "",
          danger: isMobile ? "w-full text-center" : "",
        }}
      />
    );
  };

  const renderBotIcon = () => {
    if (bot.img) {
      return (
        <img
          src={bot.img}
          alt={`${bot.name} Icon`}
          className="h-8 w-8 rounded-full"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = "none";
            e.target.nextSibling.style.display = "flex";
          }}
        />
      );
    }
    return (
      <div className="h-8 w-8 rounded-full bg-gray-600 flex items-center justify-center">
        <span className="text-white text-xs font-bold">{bot.name ? bot.name[0].toUpperCase() : "B"}</span>
      </div>
    );
  };

  return (
    <header className="flex items-center justify-between text-white px-2 py-4 relative max-w-7xl mx-auto">
      {/* Left Side */}
      <a href="/" onClick={(e) => handleNavigation(e, "/")} className="flex items-center gap-2">
        <div className="h-12 w-12 flex items-center justify-center">
          {renderBotIcon()}
          <div className="h-8 w-8 rounded-full bg-gray-600 hidden items-center justify-center">
            <span className="text-white text-xs font-bold">{bot.name ? bot.name[0].toUpperCase() : "B"}</span>
          </div>
        </div>
        <p className="text-2xl font-bold">{bot.name || "Bot"}</p>
      </a>
      {/* right Side */}
      <div className="">
        {/* Mobile */}
        <div className="block md:hidden">
          <Popover as="nav">
            {({ open }) => (
              <>
                <PopoverButton>
                  <FontAwesomeIcon icon={open ? faXmark : faBars} size="2x" className="border-transparent focus:border-transparent focus:ring-0" />
                </PopoverButton>

                <Transition as={Fragment} enter="transition ease-out duration-200" enterFrom="opacity-0 translate-y-1" enterTo="opacity-100 translate-y-0" leave="transition ease-in duration-150" leaveFrom="opacity-100 translate-y-0" leaveTo="opacity-0 translate-y-1">
                  <PopoverPanel className="absolute inset-x-0 top-full m-2 flex origin-top flex-col rounded-2xl bg-gray-800 p-4 text-lg tracking-tight text-slate-900 shadow-xl gap-2">
                    {renderNavItems(true)}
                    {renderCTAButton(true)}
                  </PopoverPanel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
        {/* Desktop */}
        <nav className="hidden md:flex items-center" style={{ gap: `${itemsGap}px` }}>
          {renderNavItems()}
          {renderCTAButton()}
        </nav>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => ({
  home_settings: state.data.bot.home_settings,
  bot: state.data.bot,
});

export default connect(mapStateToProps)(NavBar);
