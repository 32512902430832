import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display team members
 */
class Testimonials extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 2:
        return this.layout2();
      case 3:
        return this.layout3();
      default:
      case 1:
        return this.layout1();
    }
  };

  layout1 = () => {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
        {this.options?.members?.map((item, index) => (
          <div className="flex h-auto flex-nowrap gap-5 bg-background-darkless rounded-lg hover:-translate-y-2 ease-in duration-100 cursor-default p-5" key={index}>
            <img src={item.image.src} alt={`${item.name} Server Icon`} className="rounded-lg h-24 my-auto" />
            <div className="overflow-hidden flex flex-col gap-2 items-between justify-between">
              <p className="text-xl w-full">{item.message}</p>
              <div className="overflow-hidden">
                <p className="text-lg text-gray-100 w-full truncate">{item.name}</p>
                <p className="text-gray-300">{item.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout2 = () => {
    return (
      <div className="space-y-16 pt-16">
        {this.options?.members?.map((item, index) => (
          <div className="bg-background-darkless rounded-lg relative p-2 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image.src} alt={`${item.name} Server Icon`} className="rounded-full h-24 w-24 mx-auto -translate-y-2/4" />
            <div className="mt-5 -translate-y-2/4 text-center space-y-2.5">
              <p className="text-xl w-full">{item.message}</p>
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.role}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout3 = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 gap-y-16 pt-16 justify-center">
        {this.options?.members?.map((item, index) => (
          <div className="bg-background-darkless rounded-lg relative p-2 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image.src} alt={`${item.name} Server Icon`} className="rounded-full h-24 w-24 mx-auto -translate-y-2/4" />
            <div className="mt-5 -translate-y-2/4 text-center space-y-2.5">
              <p className="text-xl w-full">{item.message}</p>
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.role}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout4 = () => {
    return (
      <div className="space-y-5">
        {this.options?.members?.map((item, index) => (
          <div className="bg-background-darkless rounded-lg relative p-5 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <div className="text-center space-y-2.5">
              <p className="text-xl w-full">{item.message}</p>
              <div className="space-y-1.5">
                <div className="flex justify-center items-center gap-2">
                  <img src={item.image.src} alt={`${item.name} Server Icon`} className="rounded-full h-5 w-5" />
                  <p className="text-xl truncate">{item.name}</p>
                </div>
                <p className="text-gray-300">{item.role}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="text-white space-y-5" id={this.id}>
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Testimonials;
