import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";
import { Disclosure, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 * Component file for FAQs
 */
class FAQs extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.props.options.layout)) {
      case 1:
        return this.accordionLayout();
      case 2:
        return this.twoColumnLayout();
      case 3:
        return this.layout3();
      case 4:
        return this.gridLayout();
      default:
        return this.accordionLayout();
    }
  };

  accordionLayout = () => {
    const { faqBackgroundColor, faqTextColor, questionFontSize, answerFontSize, borderRadius } = this.props.options;
    return (
      <div className="space-y-4 mt-5">
        {this.props.options.faqs &&
          this.props.options.faqs.map((item, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div
                  className="overflow-hidden"
                  style={{
                    backgroundColor: faqBackgroundColor,
                    color: faqTextColor,
                    borderRadius: borderRadius || "8px",
                  }}
                >
                  <Disclosure.Button className="flex w-full justify-between items-center px-4 py-3 text-left font-medium focus:outline-none" style={{ fontSize: questionFontSize }}>
                    <span>{item.question}</span>
                    <FontAwesomeIcon icon={faPlus} className={`h-5 w-5 flex-shrink-0 transition-transform duration-200 ${open ? "rotate-45" : ""}`} />
                  </Disclosure.Button>
                  <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                    <Disclosure.Panel className="px-4 pt-2 pb-4" style={{ fontSize: answerFontSize }}>
                      {item.answer}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
      </div>
    );
  };

  twoColumnLayout = () => {
    return (
      <div className="flex gap-10 flex-col lg:flex-row">
        <div className="space-y-4">
          {this.props.options.faqs &&
            this.props.options.faqs.map((item, index) => (
              <div key={index}>
                <h4 className="font-bold text-lg">{item.question}</h4>
                <p>{item.answer}</p>
              </div>
            ))}
        </div>
      </div>
    );
  };

  layout3 = () => {
    return (
      <div className="divide-y divide-gray-500">
        <div className="space-y-4 mt-4 divide-y divide-gray-500">
          {this.props.options.faqs &&
            this.props.options.faqs.map((item, index) => (
              <Disclosure key={index}>
                {({ open }) => (
                  <div className="pt-4">
                    <Disclosure.Button className="flex w-full justify-between rounded-lg">
                      <span>{item.question}</span>
                      <FontAwesomeIcon icon={faChevronDown} className={`${open ? "rotate-180 transform" : ""} h-5 w-5 text-red-500`} />
                    </Disclosure.Button>
                    <Transition enter="transition duration-100 ease-out" enterFrom="transform scale-95 opacity-0" enterTo="transform scale-100 opacity-100" leave="transition duration-75 ease-out" leaveFrom="transform scale-100 opacity-100" leaveTo="transform scale-95 opacity-0">
                      <Disclosure.Panel className="pb-2 pt-4 text-sm text-gray-300">{item.answer}</Disclosure.Panel>
                    </Transition>
                  </div>
                )}
              </Disclosure>
            ))}
        </div>
      </div>
    );
  };

  gridLayout = () => {
    const { faqBackgroundColor, faqTextColor, questionFontSize, answerFontSize, borderRadius } = this.props.options;
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-5">
        {this.props.options.faqs &&
          this.props.options.faqs.map((item, index) => (
            <div
              key={index}
              className="p-6"
              style={{
                backgroundColor: faqBackgroundColor,
                color: faqTextColor,
                borderRadius: borderRadius || "8px",
              }}
            >
              <h3 className="font-semibold mb-2" style={{ fontSize: questionFontSize }}>
                {item.question}
              </h3>
              <p style={{ fontSize: answerFontSize }}>{item.answer}</p>
            </div>
          ))}
      </div>
    );
  };

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.props.options.pretitle}
          title={this.props.options.title}
          description={this.props.options.description}
          pretitleSize={this.props.options.pretitleSize || "text-base"}
          titleSize={this.props.options.titleSize || "text-2xl"}
          descriptionSize={this.props.options.descriptionSize || "text-base"}
          pretitleColor={this.props.options.pretitleColor}
          titleColor={this.props.options.titleColor}
          descriptionColor={this.props.options.descriptionColor}
          textJustification={this.props.options.textJustification || "left"}
          layout={parseInt(this.props.options?.layout)}
        />
        <div>{this.layoutRender()}</div>
      </div>
    );
  }
}

export default FAQs;
