import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuthProvider from '../../AuthProvider';
import history from '../../utils/history';
import Cookies from "universal-cookie";
import ButtonRenderer from '../elements/ButtonRenderer';
import { Helmet } from 'react-helmet';
import NavBar from '../ui/NavBar';

export class Servers extends Component {
    getBackgroundStyle = () => {
        const { home_settings } = this.props.bot;
        if (home_settings?.backgroundType === 'solid-color') {
            return { backgroundColor: home_settings.backgroundColor || '#1a202c' };
        } else if (home_settings?.backgroundType === 'linear-gradient') {
            const [color1, color2] = home_settings.gradientColors || ['#1a202c', '#2d3748'];
            const angle = home_settings.gradientAngle || 45;
            return { background: `linear-gradient(${angle}deg, ${color1}, ${color2})` };
        }
        return { backgroundColor: '#1a202c' };
    };

    getFontStyle = () => {
        const { home_settings } = this.props.bot;
        if (home_settings?.font) {
            return {
                fontFamily: `'${home_settings.font}', sans-serif`,
                WebkitFontSmoothing: 'antialiased',
                MozOsxFontSmoothing: 'grayscale'
            };
        }
        return {}; // Return an empty object if no font settings
    };

    renderServers = () => {
        var servers = [];

        var servers_array = this.props.servers;
        // Order by joined
        servers_array.sort((a, b) => {
            if (a.joined && !b.joined) return -1;
            if (!a.joined && b.joined) return 1;
            return 0;
        });

        servers_array.forEach(server => {
            servers.push(
                <div key={server.id} className='flex relative flex-col items-center'>
                    <div className='w-full h-[150px] blur-sm bg-gradient-to-r from-cyan-500 to-blue-500' style={{
                        backgroundImage: `url('https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp')`,
                        backgroundSize: 'cover',
                    }}>
                    </div>
                    <img alt="server icon" className='w-20 top-[35px] border-white border-2 absolute blur-none rounded-full h-20' onError={(e) => {
                        e.target.onError = null;
                        e.target.src = process.env.PUBLIC_URL + "/img/default.png";
                    }} src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp` || process.env.PUBLIC_URL + "/img/default.png"}></img>
                    <div className='mt-3 flex flex-row items-center w-full py-3'>
                        <div>
                            <h1 className='text-lg font-bold text-white'>{server.name}</h1>
                            <span>{server.owner ? "Owner" : "Bot Admin"}</span>
                        </div>
                        <ButtonRenderer
                            buttons={[{
                                text: server.joined ? 'Settings' : 'Invite',
                                link: server.joined ? `/dashboard/${server.id}` : this.getInviteUrl(server.id),
                                type: server.joined ? 'primary' : 'secondary',
                                style: 'default'
                            }]}
                            containerClass="ml-auto"
                            customClasses={this.props.bot.home_settings?.buttonStyles}
                            buttonColors={this.props.bot.home_settings?.buttonColors}
                        />
                    </div>
                </div>
            );
        });

        return servers;
    };

    getInviteUrl = (serverId) => {
        var permissions = 8;
        if (this.props.bot?.invite_settings) {
            permissions = this.props.bot?.invite_settings.invite_perms_int;
        }
        var url = `https://discord.com/oauth2/authorize?client_id=${this.props.bot.id}&guild_id=${serverId}&scope=applications.commands%20bot&permissions=${permissions}`;
        if (this.props.bot?.invite_settings?.redirect) {
            url += `&redirect_uri=${window.location.origin}/redirect&response_type=code`;
            const cookies = new Cookies();
            cookies.set("redirect_server_id", `'${serverId}'`);
        }
        return url;
    };

    render() {
        const backgroundStyle = this.getBackgroundStyle();
        const fontStyle = this.getFontStyle();
        const { homeType } = this.props.bot.home_settings || {};

        return (
            <AuthProvider>
                <div className="scroll-smooth relative min-h-screen" style={{ ...backgroundStyle, ...fontStyle }}>
                    <Helmet>
                        <title>Servers - {this.props.bot.dash_settings.title ? this.props.bot.dash_settings.title : this.props.bot.name}</title>
                        {this.props.bot.home_settings?.font && (
                            <link
                                href={`https://fonts.googleapis.com/css?family=${this.props.bot.home_settings.font.replace(' ', '+')}&display=swap`}
                                rel="stylesheet"
                            />
                        )}
                    </Helmet>

                    {homeType === 'advanced' && <NavBar bot={this.props.bot} />}

                    <main className="container mx-auto px-4 py-8">
                        <div className='flex flex-col items-center gap-y-24'>
                            <h1 className='text-4xl font-bold text-white'>Choose a Server</h1>

                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full gap-8'>
                                {this.renderServers()}
                            </div>
                        </div>
                    </main>
                </div>
            </AuthProvider>
        );
    }
}

const mapStateToProps = (state) => ({
    servers: state.data.servers,
    bot: state.data.bot
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Servers);
