import React, { Component } from "react";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component to display team members
 */
class Team extends Component {
  /**
   * Inits the component and the state
   * @param {*} props
   */
  constructor(props) {
    super(props);

    this.state = {};
    this.options = this.props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (this.options?.layout) {
      case 2:
        return this.layout2();
      default:
      case 1:
        return this.layout1();
    }
  };

  layout1 = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {this.options?.members?.map((item, index) => (
          <div className="flex h-24 flex-nowrap gap-5 bg-background-darkless rounded-lg hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image?.src} alt={`${item.name} Server Icon`} className="rounded-lg h-24 w-24" />
            <div className="my-auto overflow-hidden">
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.role}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  layout2 = () => {
    return (
      <div className="flex flex-wrap gap-4 gap-y-16 mt-16 justify-center">
        {this.options?.members?.map((item, index) => (
          <div className="w-full md:w-1/3 lg:w-1/5 bg-background-darkless rounded-lg relative p-2 hover:-translate-y-2 ease-in duration-100 cursor-default" key={index}>
            <img src={item.image?.src} alt={`${item.name} Server Icon`} className="rounded-full h-24 w-24 mx-auto -translate-y-2/4" />
            <div className="mt-1 -translate-y-2/4 text-center">
              <p className="text-xl w-full truncate">{item.name}</p>
              <p className="text-gray-300">{item.role}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className="text-white">
        <DisplayHeader
          pretitle={this.options.pretitle}
          title={this.options.title}
          description={this.options.description}
          pretitleSize={this.options.pretitleSize || "text-base"}
          titleSize={this.options.titleSize || "text-2xl"}
          descriptionSize={this.options.descriptionSize || "text-base"}
          pretitleColor={this.options.pretitleColor}
          titleColor={this.options.titleColor}
          descriptionColor={this.options.descriptionColor}
          textJustification={this.options.textJustification || "left"}
          layout={parseInt(this.options?.layout)}
        />
        {this.layoutRender()}
      </div>
    );
  }
}

export default Team;
