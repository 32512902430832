import React, { Component } from "react";
import ButtonRenderer from "../elements/ButtonRenderer";
import DisplayHeader from "../ui/DisplayHeader";

/**
 * Component file for header
 */
class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.options = props.options;
    this.id = this.props.id;
  }

  /**
   * Renders the layouts
   * @returns the layout that is specified
   */
  layoutRender = () => {
    switch (parseInt(this.options?.layout)) {
      case 3:
        return this.layout3();
      case 2:
        return this.layout2();
      default:
      case 1:
        return this.layout1();
    }
  };

  renderPretitle = () => {
    if (this.options.pretitle) {
      return <p className={`text-gray-300 font-semibold ${this.options.pretitleSize || "text-lg"} mb-2`}>{this.options.pretitle}</p>;
    }
    return null;
  };

  layout1 = () => {
    return (
      <div className="flex flex-col items-center text-center">
        {this.options.image?.src && <img src={this.options.image.src} alt={this.options.image?.caption || "Header Image"} className={`w-32 h-32 mb-8 ${this.options.image.rounded ? "rounded-full" : ""}`} />}
        <div className="max-w-2xl">
          {this.renderPretitle()}
          {this.options.title && <h1 className={`text-white font-bold ${this.options.titleSize || "text-4xl"} mb-4`}>{this.options.title}</h1>}
          {this.options.description && <p className={`text-gray-200 font-medium ${this.options.descriptionSize || "text-xl"} mb-6`}>{this.options.description}</p>}
          {this.options.buttons && this.options.buttons.length > 0 && (
            <div className="flex justify-center">
              <ButtonRenderer buttons={this.options.buttons} />
            </div>
          )}
        </div>
      </div>
    );
  };

  layout2 = () => {
    return (
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
          {this.renderPretitle()}
          {this.options.title && <h1 className={`text-white font-bold ${this.options.titleSize || "text-4xl"} mb-4`}>{this.options.title}</h1>}
          {this.options.description && <p className={`text-gray-200 font-medium ${this.options.descriptionSize || "text-xl"} mb-6`}>{this.options.description}</p>}
          {this.options.buttons && this.options.buttons.length > 0 && <ButtonRenderer buttons={this.options.buttons} />}
        </div>
        {this.options.image?.src && (
          <div className="md:w-1/2 flex justify-center">
            <img src={this.options.image.src} alt={this.options.image?.caption || "Header Image"} className={`w-full max-w-md h-auto ${this.options.image.rounded ? "rounded-full" : ""}`} />
          </div>
        )}
      </div>
    );
  };

  layout3 = () => {
    return (
      <div className="flex flex-col md:flex-row-reverse items-center justify-between">
        <div className="md:w-1/2 mb-8 md:mb-0 md:pl-8">
          {this.renderPretitle()}
          {this.options.title && <h1 className={`text-white font-bold ${this.options.titleSize || "text-4xl"} mb-4`}>{this.options.title}</h1>}
          {this.options.description && <p className={`text-gray-200 font-medium ${this.options.descriptionSize || "text-xl"} mb-6`}>{this.options.description}</p>}
          {this.options.buttons && this.options.buttons.length > 0 && <ButtonRenderer buttons={this.options.buttons} />}
        </div>
        {this.options.image?.src && (
          <div className="md:w-1/2 flex justify-center">
            <img src={this.options.image.src} alt={this.options.image?.caption || "Header Image"} className={`w-full max-w-md h-auto ${this.options.image.rounded ? "rounded-full" : ""}`} />
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div className="pt-20" id={this.id}>
        {this.layoutRender()}
      </div>
    );
  }
}

export default Header;
